import { ReloadOutlined, SearchOutlined } from '@ant-design/icons'
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  notification,
  Pagination,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
} from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'
import JsFileDownload from 'js-file-download'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../../shared/api'
import { AnalysisGroupListProvider } from '../../../shared/api/context/AnalysisGroupListContext'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import {
  FilterInfoColumn,
  FundDropdownCodeType,
  FundDropdownType,
  FundSearchColumnResponse,
  FundSearchWhereParams,
} from '../../../shared/api/models/FundDetails'
import { FilterTypeColumnTable } from '../../../shared/api/models/Funds'
import { Colors } from '../../../shared/colors'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import { LeftNavFirmEnum } from '../../../shared/SearchFirmLabel'
import { LeftNavFundEnum } from '../../../shared/SearchFundLabel'
import GenUtil from '../../../shared/utils/gen-util'
import { addTabs, FundFirmTypeEnum } from '../../../slice/appSettingsSlice'
import AddToFavoritedModal from './AddToFavoritedModal'
import AddToIndexModal from './AddToIndexModal'
import AddToModal from './AddToModal'
import { FundSearchColumnContext } from './ColumnBuilder/FundSearchColumnContext'
import { FundSearchColumnPropsExtended } from './ColumnBuilder/FundSearchColumnSource'
import DeleteFundModal from './DeleteFundModal'
import { updateFundSearchParams } from './SearchForm/redux/searchParamsSlice'
import { UpdateBenchmarkModal } from './UpdateBenchmarkModal'

const Wrapper = styled.div`
  width: 100%;
`

const FundFirmName = styled('span')`
  color: ${Colors.primary};
  cursor: pointer;
`
const WrapperTable = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-table-container {
    border-bottom: solid 1px #f0f0f0;
  }
  .ant-table-fixed-left .ant-table-body-inner {
    overflow-x: hidden;
  }
  .ant-table-body {
    min-height: calc(100vh - 468px);
  }

  .ant-table-footer {
    padding: 16px 0 0 0;
    background: none;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`
const FooterLeft = styled.div`
  margin-bottom: 8px;
`
const FooterRight = styled.div`
  display: flex;
  justify-self: stretch;
`
type ListTabType = {
  id: string
  name: string
  type: FundFirmTypeEnum
}

type Props = { onChangeFavorited: () => void }
export const FundSearch: React.FC<Props> = ({ onChangeFavorited }) => {
  const { fundDataDropdown, dataMinoritySearch } = React.useContext(FundDetailDropdownContext)

  const { user } = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch()
  // column section
  const { dataAllColumns, loading: allColumnLoading, defaultColumns } = React.useContext(FundSearchColumnContext)
  const { fundSearchColumns } = useSelector((state: RootState) => state.appSettings)

  const columns = React.useMemo(() => {
    return dataAllColumns && fundSearchColumns.length
      ? fundSearchColumns.reduce((acc, currKey) => {
          const foundColumnKey = Object.keys(dataAllColumns).find((item) => item === currKey)
          if (foundColumnKey) {
            const currColumn = {
              title: dataAllColumns[foundColumnKey].human_readable_name,
              dataIndex: foundColumnKey,
              key: foundColumnKey,
              filter_type: dataAllColumns[foundColumnKey].filter_type,
              sorter: true,
            }
            return [...acc, currColumn]
          }
          return acc
        }, [] as FundSearchColumnPropsExtended[])
      : defaultColumns
  }, [dataAllColumns, defaultColumns, fundSearchColumns])

  //Fetch Legacy-Strategy
  const { invoke: getLegacyStrategy, data: dataLegacy } = useServiceState(
    APIService.fundDetailService.fetchLegacyStrategy,
  )
  const { invoke: getAsiStrategy, data: dataAsi } = useServiceState(APIService.fundDetailService.fetchAsiStrategy)

  React.useEffect(() => {
    getLegacyStrategy()
    getAsiStrategy()
  }, [getAsiStrategy, getLegacyStrategy])

  const searchInput = React.useRef<Input>(null)
  const appData = useSelector((state: RootState) => state.appData)

  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(25)

  const [selectedFundIds, setSelectedFundIds] = React.useState<string[]>()
  const [selectedFundName, setSelectedFundName] = React.useState<string>()
  const [loadingExport, setLoadingExport] = React.useState(false)

  const [showAddToGroup, setShowAddToGroup] = React.useState(false)
  const [showAddToIndex, setShowAddToIndex] = React.useState(false)
  const [showAddToFavorited, setShowAddToFavorited] = React.useState(false)
  const [showUpdateBenchmark, setShowUpdateBenchmark] = React.useState(false)
  const [selectedFundsAddFavorited, setSelectedFundsAddFavorited] = React.useState<
    { fund_id: string; fund_name: string }[]
  >()

  const [showDeleteFund, setShowDeleteFund] = React.useState(false)

  const [sortBy, setSortBy] = React.useState<string | undefined>()
  const [orderBy, setOrderBy] = React.useState<string | undefined>()

  const { fundSearchParams: fundSearchParamsRedux } = useSelector((state: RootState) => state.searchParams)

  const filteredInfo = React.useMemo(() => {
    if (Object.keys(fundSearchParamsRedux).length === 0) {
      return {}
    }
    const filtered = Object.keys(fundSearchParamsRedux).reduce((acc, key) => {
      if (key === 'is_minority_owned_firm' || key === 'is_minority_managed_fund') {
        return acc
      }

      return { ...acc, [key]: fundSearchParamsRedux[key as keyof FilterInfoColumn]?.value }
    }, {} as FilterInfoColumn)
    return filtered
  }, [fundSearchParamsRedux])

  const { invoke: searchFunds, data: fundData, loading } = useServiceState(APIService.fundService.searchFunds)

  const { data: allFundIds, invoke: invokeSearchFundIds } = useServiceState(
    APIService.fundService.fetchFundSearchAllFundId,
  )

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size)
    if (current !== 1) {
      setPage(1)
    }
  }

  const handleTableChange = (sorter: SorterResult<any>) => {
    setSortBy(sorter.order ? sorter.columnKey?.toString() : undefined)
    setOrderBy(sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : undefined)
  }

  const fundSearchParams = React.useMemo(() => {
    setPage(1)
    if (Object.keys(filteredInfo).length === 0) {
      return {}
    }
    const searchParams: Partial<FundSearchWhereParams> = Object.keys(filteredInfo).reduce((acc, key) => {
      if (filteredInfo[key as keyof FilterInfoColumn] && dataAllColumns && dataAllColumns[key]?.filter_type) {
        if (
          [
            'sub_strategy_code_new',
            'strategy_code_new',
            'fund_status_code_code',
            'region_inv_focus_code',
            'region_inv_focus_country_code',
          ].includes(key)
        ) {
          return { ...acc, [key]: filteredInfo[key as keyof FilterInfoColumn] }
        }
        if (['fund_cmic', 'has_securities'].includes(key)) {
          return { ...acc, [key]: filteredInfo[key as keyof FilterInfoColumn] }
        }
        switch (dataAllColumns[key].filter_type) {
          case 'boolean':
            return {
              ...acc,
              [key]: filteredInfo[key as keyof FilterInfoColumn]?.[0],
            }
          case 'date':
          case 'year_month':
            const lteDate = filteredInfo[key as keyof FilterInfoColumn]?.[1]
            const gteDate = filteredInfo[key as keyof FilterInfoColumn]?.[0]
            return {
              ...acc,
              [key]: {
                lte: lteDate && lteDate !== '' ? lteDate : undefined,
                gte: gteDate && gteDate !== '' ? gteDate : undefined,
              },
            }
          case 'string_search':
            if (dataAllColumns[key].supports_exact_match === true) {
              const type = fundSearchParamsRedux[key].type
              if (type === 'contains') {
                return { ...acc, [key]: { contains: filteredInfo[key as keyof FilterInfoColumn]?.[0] } }
              }
              if (type === 'equals') {
                return { ...acc, [key]: { equals: filteredInfo[key as keyof FilterInfoColumn]?.[0] } }
              }
              return acc
            }
            return { ...acc, [key]: filteredInfo[key as keyof FilterInfoColumn]?.[0] }

          case 'multiselect':
            return { ...acc, [key]: filteredInfo[key as keyof FilterInfoColumn] }
          case 'numeric':
            const lte = filteredInfo[key as keyof FilterInfoColumn]?.[1]
            const gte = filteredInfo[key as keyof FilterInfoColumn]?.[0]
            return {
              ...acc,
              [key]: {
                lte: lte && lte !== '' ? Number(lte) : undefined,
                gte: gte && gte !== '' ? Number(gte) : undefined,
              },
            }
          case 'array_anyof':
            const type = fundSearchParamsRedux[key].type
            if (type === 'array_anyof') {
              return { ...acc, [key]: { array_anyof: filteredInfo[key as keyof FilterInfoColumn] } }
            }
          // case 'year_month':
          //   return { ...acc, [key]: { lte: filteredInfo[key as keyof FilterInfoColumn]?.[0] } }
          default:
            break
        }
      }
      // skip
      return acc
    }, {})
    return searchParams
  }, [dataAllColumns, filteredInfo, fundSearchParamsRedux])

  React.useEffect(() => {
    if (!dataAllColumns) {
      return
    }
    searchFunds({
      where: Object.keys(fundSearchParams).length > 0 ? fundSearchParams : undefined,
      peer_group_code:
        filteredInfo.peer_group_code && filteredInfo.peer_group_code.length > 0
          ? filteredInfo.peer_group_code
          : undefined,
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      columns: fundSearchColumns,
    })
    invokeSearchFundIds({
      where: fundSearchParams,
      peer_group_code:
        filteredInfo.peer_group_code && filteredInfo.peer_group_code.length > 0
          ? filteredInfo.peer_group_code
          : undefined,
    })
  }, [
    dataAllColumns,
    fundSearchParams,
    searchFunds,
    invokeSearchFundIds,
    orderBy,
    page,
    pageSize,
    sortBy,
    fundSearchColumns,
    filteredInfo,
  ])

  const exportFundSearch = async () => {
    setLoadingExport(true)
    try {
      const res = await APIService.fundService.postDownloadFile({
        file_type: 'MANAGER_LINK_INTERNAL_FUND_SEARCH_EXPORT',
        data: {
          where: Object.keys(fundSearchParams).length > 0 ? fundSearchParams : undefined,
          peer_group_code:
            filteredInfo.peer_group_code && filteredInfo.peer_group_code.length > 0
              ? filteredInfo.peer_group_code
              : undefined,
          columns: fundSearchColumns,
        },
      })

      const resFile = await APIService.fundService.downloadFileByUrl(res.data.url)
      JsFileDownload(resFile.data, 'search-results.csv', 'application/csv')
    } catch (error) {
      console.log(error.response)
      notification.error({ message: 'Failed to export Funds!' })
    } finally {
      setLoadingExport(false)
    }
  }

  const refetch = () => {
    searchFunds({
      where: Object.keys(fundSearchParams).length > 0 ? fundSearchParams : undefined,
      peer_group_code:
        filteredInfo.peer_group_code && filteredInfo.peer_group_code.length > 0
          ? filteredInfo.peer_group_code
          : undefined,
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      columns: fundSearchColumns,
    })
    invokeSearchFundIds({
      where: fundSearchParams,
      peer_group_code:
        filteredInfo.peer_group_code && filteredInfo.peer_group_code.length > 0
          ? filteredInfo.peer_group_code
          : undefined,
    })
  }

  const handleClearAllFilter = () => {
    dispatch(updateFundSearchParams({}))
  }
  const removeFromFilter = (dataIndex: string, clearFilters?: (() => void) | undefined) => {
    const editedSearch = Object.keys(fundSearchParamsRedux).reduce((acc, key) => {
      if (key === dataIndex) {
        return acc
      }
      return { ...acc, [key]: fundSearchParamsRedux[key] }
    }, {})
    dispatch(updateFundSearchParams(editedSearch))
    clearFilters && clearFilters()
  }

  const addToFilter = (dataIndex: string, selectedKeys: React.ReactText[], confirm: (() => void) | undefined) => {
    dispatch(
      updateFundSearchParams({
        ...fundSearchParamsRedux,
        [dataIndex]: { value: selectedKeys as string[] },
      }),
    )
    confirm && confirm()
  }

  const getColumnSearchProps = (
    dataIndex: string,
    filterType: FilterTypeColumnTable,
  ): ColumnProps<FundSearchColumnResponse> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const optionsDropdown = (fundDataDropdown && fundDataDropdown[dataIndex as keyof FundDropdownType]) || []

      // Hard-coded cases
      if (
        [
          'sub_strategy_code_new',
          'strategy_code_new',
          'fund_status_code_code',
          'region_inv_focus_code',
          'region_inv_focus_country_code',
        ].includes(dataIndex)
      ) {
        let codeOptions: { label: string; value: string }[] = []
        switch (dataIndex) {
          case 'strategy_code_new':
            if (!appData?.strategies || !appData?.strategies?.length) {
              return
            }
            codeOptions = appData.strategies.map((item) => ({
              value: item.strategy_code.toString(),
              label: item.strategy_code.toString(),
            }))
            break
          case 'sub_strategy_code_new':
            if (!appData || !appData.subStrategies || !appData.subStrategies.length) {
              return
            }
            const uniqueSubStrategy = new Set(
              appData.subStrategies.map((item) => {
                return item.sub_strategy_code
              }),
            )
            uniqueSubStrategy.forEach((item) =>
              codeOptions.push({
                value: item.toString(),
                label: item.toString(),
              }),
            )
            break
          case 'region_inv_focus_code':
            codeOptions = fundDataDropdown
              ? fundDataDropdown.region_inv_focus.map((item) => ({
                  value: item.code_code,
                  label: item.code_code,
                }))
              : []
            break
          case 'region_inv_focus_country_code':
            codeOptions = fundDataDropdown
              ? fundDataDropdown.region_inv_focus_country.map((item) => ({
                  value: item.code_code,
                  label: item.code_code,
                }))
              : []
            break
          case 'fund_status_code_code':
            codeOptions = fundDataDropdown
              ? fundDataDropdown.fund_status_code.map((item) => ({
                  value: item.code_code,
                  label: item.code_code,
                }))
              : []
            break
          default:
            break
        }
        return (
          <Row style={{ padding: 8 }}>
            <Space align="start">
              <Select
                value={selectedKeys}
                style={{ width: 200 }}
                mode="multiple"
                allowClear
                onChange={(e) => setSelectedKeys(e as string[])}
                options={codeOptions}
                showSearch
                filterOption={(input, option: any) => {
                  return option.label.toLowerCase().includes(input.toLowerCase())
                }}
              />
              <Button
                type="primary"
                onClick={() => {
                  if (selectedKeys.length > 0) {
                    return addToFilter(dataIndex, selectedKeys, confirm)
                  }
                  return removeFromFilter(dataIndex, clearFilters)
                }}
                icon={<SearchOutlined />}
              />
              <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} icon={<ReloadOutlined />} />
            </Space>
          </Row>
        )
      }
      if (['fund_cmic', 'has_securities'].includes(dataIndex)) {
        return (
          <Row style={{ padding: 8 }}>
            <Space align="start">
              <Select
                value={selectedKeys}
                style={{ width: 200 }}
                mode="multiple"
                allowClear
                onChange={(e) => setSelectedKeys(e as string[])}
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                  { label: 'Not Responded', value: 'Not Responded' },
                  { label: 'Does Not Apply to Fund', value: 'Does Not Apply to Fund' },
                ]}
                showSearch
                filterOption={(input, option: any) => {
                  return option.label.toLowerCase().includes(input.toLowerCase())
                }}
              />
              <Button
                type="primary"
                onClick={() => {
                  if (selectedKeys.length > 0) {
                    return addToFilter(dataIndex, selectedKeys, confirm)
                  }
                  return removeFromFilter(dataIndex, clearFilters)
                }}
                icon={<SearchOutlined />}
              />
              <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} icon={<ReloadOutlined />} />
            </Space>
          </Row>
        )
      }
      switch (filterType) {
        case 'string_search':
          if (
            dataAllColumns &&
            dataAllColumns[dataIndex as keyof FundSearchColumnResponse].supports_exact_match === true
          ) {
            return (
              <Row style={{ padding: 8 }}>
                <Space>
                  <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    allowClear
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    //onPressEnter={() => confirm && confirm()}
                  />
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatch(
                        updateFundSearchParams({
                          ...fundSearchParamsRedux,
                          [dataIndex]: { value: selectedKeys as string[], type: 'equals' },
                        }),
                      )

                      confirm && confirm()
                    }}
                  >
                    Exact
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatch(
                        updateFundSearchParams({
                          ...fundSearchParamsRedux,
                          [dataIndex]: { value: selectedKeys as string[], type: 'contains' },
                        }),
                      )

                      confirm && confirm()
                    }}
                  >
                    Partial
                  </Button>
                  <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} icon={<ReloadOutlined />} />
                </Space>
              </Row>
            )
          }
          return (
            <Row style={{ padding: 8 }}>
              <Space>
                <Input
                  ref={searchInput}
                  placeholder={`Search ${dataIndex}`}
                  value={selectedKeys[0]}
                  allowClear
                  onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => addToFilter(dataIndex, selectedKeys, confirm)}
                />
                <Button
                  type="primary"
                  onClick={() => addToFilter(dataIndex, selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                />
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} icon={<ReloadOutlined />} />
              </Space>
            </Row>
          )

        case 'boolean':
          return (
            <Row style={{ padding: 8 }}>
              <Radio.Group
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys([e.target.value])}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
              <Space>
                <Button
                  type="primary"
                  onClick={() => addToFilter(dataIndex, selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </Row>
          )
        case 'date':
          return (
            <div style={{ padding: 8 }}>
              <DatePicker
                placeholder="GTE Day"
                value={selectedKeys[0] ? moment(selectedKeys[0].toString()) : undefined}
                onChange={(e) => {
                  setSelectedKeys([e ? moment(e).format('YYYY-MM-DD') : '', selectedKeys[1]])
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <DatePicker
                placeholder="LTE Day"
                value={selectedKeys[1] ? moment(selectedKeys[1]) : undefined}
                onChange={(e) => {
                  setSelectedKeys([selectedKeys[0], e ? moment(e).format('YYYY-MM-DD') : ''])
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => addToFilter(dataIndex, selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </div>
          )

        case 'multiselect':
          let options: { label: string; value: string }[] = []
          switch (dataIndex) {
            case 'firm_denomination':
            case 'denomination':
              options = (optionsDropdown as Array<FundDropdownCodeType>).map((item) => ({
                value: item.code_code,
                label: item.code_code,
              }))
              break
            case 'strategy_new':
              if (!appData?.strategies || !appData?.strategies.length) {
                return
              }
              options = appData.strategies.map((item) => ({
                value: item.strategy_name.toString(),
                label: item.strategy_name.toString(),
              }))
              break
            case 'strategy':
              if (!dataLegacy?.legacy_strategies || !dataLegacy?.legacy_strategies?.length) {
                return
              }
              options = dataLegacy.legacy_strategies.map((item) => ({
                value: item.strategy_code.toString(),
                label: item.strategy_name.toString(),
              }))
              break
            case 'sub_strategy':
              if (!dataLegacy?.sub_strategies || !dataLegacy?.sub_strategies?.length) {
                return
              }
              const uniqueSubStrategy = new Set(
                dataLegacy.sub_strategies.map((item) => {
                  return item.sub_strategy_name
                }),
              )
              uniqueSubStrategy.forEach((item) => options.push({ value: item, label: item }))

              break
            case 'sub_strategy_new':
              if (!appData?.subStrategies || !appData?.subStrategies.length) {
                return
              }
              const uniqueNewSubStrategy = new Set(
                appData.subStrategies.map((item) => {
                  return item.sub_strategy_name
                }),
              )
              uniqueNewSubStrategy.forEach((item) => options.push({ value: item, label: item }))

              break
            case 'asi_strategy':
              if (!dataAsi?.asi_strategies || !dataAsi?.asi_strategies?.length) {
                return
              }
              options = dataAsi?.asi_strategies.map((item) => ({
                value: item.name,
                label: item.name,
              }))
              break
            case 'asi_sub_strategy':
              if (!dataAsi?.asi_sub_strategies || !dataAsi?.asi_sub_strategies?.length) {
                return
              }
              const uniqueAsiSubStrategy = new Set(
                dataAsi?.asi_sub_strategies.map((item) => {
                  return item.name
                }),
              )
              uniqueAsiSubStrategy.forEach((item) => options.push({ value: item, label: item }))

              break
            case 'esg_strategy':
              if (!appData?.esgStrategies || !appData?.esgStrategies?.length) {
                return
              }
              const uniqueESGStrategy = new Set(appData.esgStrategies.map((item) => item.name))
              uniqueESGStrategy.forEach((item) => options.push({ value: item, label: item }))
              break
            case 'esg_sub_strategy':
              if (!appData?.esgSubStrategies || !appData?.esgSubStrategies?.length) {
                return
              }
              const uniqueESGSubStrategy = new Set(appData.esgSubStrategies.map((item) => item.name))
              uniqueESGSubStrategy.forEach((item) => options.push({ value: item, label: item }))
              break
            default:
              options = (optionsDropdown as Array<FundDropdownCodeType | string>).map((item) =>
                typeof item === 'string'
                  ? { value: item, label: item }
                  : { value: item.code_description, label: item.code_description },
              )
          }

          return (
            <Row style={{ padding: 8 }}>
              <Space align="start">
                <Select
                  value={selectedKeys}
                  style={{ width: 200 }}
                  mode="multiple"
                  allowClear
                  onChange={(e) => setSelectedKeys(e as string[])}
                  options={options}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    if (selectedKeys.length > 0) {
                      return addToFilter(dataIndex, selectedKeys, confirm)
                    }
                    return removeFromFilter(dataIndex, clearFilters)
                  }}
                  icon={<SearchOutlined />}
                />
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} icon={<ReloadOutlined />} />
              </Space>
            </Row>
          )
        case 'array_anyof':
          const newOptions: { value: any; label: any }[] | undefined = dataMinoritySearch?.map((item) => {
            return { value: item, label: item }
          })
          return (
            <Row style={{ padding: 8 }}>
              <Space align="start">
                <Select
                  value={selectedKeys}
                  style={{ width: 200 }}
                  mode="multiple"
                  allowClear
                  onChange={(e) => setSelectedKeys(e as string[])}
                  options={newOptions}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    if (selectedKeys.length > 0) {
                      dispatch(
                        updateFundSearchParams({
                          ...fundSearchParamsRedux,
                          [dataIndex]: { value: selectedKeys as string[], type: 'array_anyof' },
                        }),
                      )
                      confirm && confirm()
                    } else {
                      removeFromFilter(dataIndex, clearFilters)
                    }
                  }}
                  icon={<SearchOutlined />}
                />
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} icon={<ReloadOutlined />} />
              </Space>
            </Row>
          )
        case 'numeric':
          return (
            <div style={{ padding: 8 }} tabIndex={0}>
              <Input
                placeholder={`GTE ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys([e.target.value, selectedKeys[1]])}
                onPressEnter={() => addToFilter(dataIndex, selectedKeys, confirm)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
                type="number"
              />
              <Input
                placeholder={`LTE ${dataIndex}`}
                value={selectedKeys[1]}
                onChange={(e) => setSelectedKeys([selectedKeys[0], e.target.value])}
                onPressEnter={() => addToFilter(dataIndex, selectedKeys, confirm)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
                type="number"
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => addToFilter(dataIndex, selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </div>
          )
        case 'year_month':
          return (
            <div style={{ padding: 8 }}>
              <DatePicker
                picker="month"
                value={selectedKeys[0] ? moment(selectedKeys[0].toString()) : undefined}
                onChange={(e) => {
                  setSelectedKeys([e ? moment(e).format('YYYY-MM') : '', selectedKeys[1]])
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <DatePicker
                picker="month"
                value={selectedKeys[1] ? moment(selectedKeys[1].toString()) : undefined}
                onChange={(e) => {
                  setSelectedKeys([selectedKeys[0], e ? moment(e).format('YYYY-MM') : ''])
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => addToFilter(dataIndex, selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                />

                <Button
                  onClick={() => removeFromFilter(dataIndex, clearFilters)}
                  size="small"
                  style={{ width: 90 }}
                  icon={<ReloadOutlined />}
                />
              </Space>
            </div>
          )
        default:
          break
      }
    },

    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const handleClearAll = () => {
    setSelectedFundIds([])
  }

  const handleSelectAll = () => {
    if (!fundData || !allFundIds) {
      return
    }
    setSelectedFundIds(allFundIds.fund_ids)
  }
  const handleAddTo = () => {
    setShowAddToGroup(true)
  }

  const handleAddToIndex = () => {
    setShowAddToIndex(true)
  }
  const handleAddToFavorited = () => {
    setShowAddToFavorited(true)
  }

  const renderAddToList = () => (
    <Menu
      onClick={(e) => {
        if (e.key === '1') {
          handleAddTo()
          return
        }
        if (e.key === '2') {
          handleAddToIndex()
          return
        }
        if (e.key === '3') {
          handleAddToFavorited()
        }
      }}
    >
      <Menu.Item key="1">Add To Group</Menu.Item>
      <Menu.Item key="2">Add To Index</Menu.Item>
      <Menu.Item key="3">Add To Favorited</Menu.Item>
    </Menu>
  )

  return (
    <Wrapper className="card-container">
      <WrapperTable>
        <div style={{ alignSelf: 'start', maxHeight: 60, overflowY: 'auto' }}>
          {Object.keys(filteredInfo).map((item) => {
            if (item === 'peer_group_code') {
              return
            }
            const value = filteredInfo[item as keyof FilterInfoColumn]
            if (!value || !dataAllColumns || !dataAllColumns[item]) {
              return
            }

            if (
              [
                'sub_strategy_code_new',
                'strategy_code_new',
                'fund_status_code_code',
                'region_inv_focus_code',
                'region_inv_focus_country_code',
              ].includes(item)
            ) {
              const tagValue = value.join(', ')
              return (
                <Tag
                  key={item}
                  closable
                  onClose={(e: any) => {
                    e.stopPropagation()
                    removeFromFilter(item)
                  }}
                >
                  <strong>{dataAllColumns[item].human_readable_name}: </strong>
                  <Tooltip title={tagValue} key={tagValue}>
                    <span>{tagValue.length > 50 ? `${tagValue.slice(0, 50)}...` : tagValue || null}</span>
                  </Tooltip>
                </Tag>
              )
            }
            if (typeof value[0] === 'boolean') {
              return (
                <Tag
                  key={item}
                  closable
                  onClose={(e: any) => {
                    e.stopPropagation()
                    removeFromFilter(item)
                    // dispatch(updateSearchParams({ ...fundSearchParamsRedux, [item]: null }))
                  }}
                >
                  <strong>{dataAllColumns[item].human_readable_name}: </strong>
                  <span>{value[0] === true ? 'Yes' : 'No'}</span>
                </Tag>
              )
            }
            if (dataAllColumns[item]?.filter_type === 'numeric' || dataAllColumns[item]?.filter_type === 'date') {
              return (
                <Tag
                  key={item}
                  closable
                  onClose={(e: any) => {
                    e.stopPropagation()
                    removeFromFilter(item)
                    // dispatch(updateSearchParams({ ...fundSearchParamsRedux, [item]: null }))
                  }}
                >
                  <strong>{dataAllColumns[item].human_readable_name}: </strong>

                  <span>{value[0] && value[0] !== '' ? value[0] : 'N/A'}</span>
                  <span>{value[1] && value[1] !== '' ? ' - ' + value[1] : ' - N/A'}</span>
                </Tag>
              )
            }
            if (dataAllColumns[item]?.supports_exact_match === true) {
              const type = fundSearchParamsRedux[item].type
              const tagValue = value.join(', ')
              return tagValue.length > 50 ? (
                <Tooltip title={tagValue} key={tagValue}>
                  <Tag
                    key={item}
                    closable
                    onClose={(e: any) => {
                      e.stopPropagation()
                      removeFromFilter(item)
                    }}
                  >
                    <strong>{dataAllColumns[item].human_readable_name}: </strong>
                    {type && <span>[{type.toUpperCase()}] </span>}
                    <span>{tagValue.length > 50 ? `${tagValue.slice(0, 50)}...` : tagValue || null}</span>
                  </Tag>
                </Tooltip>
              ) : (
                <Tag
                  key={item}
                  closable
                  onClose={(e: any) => {
                    e.stopPropagation()
                    removeFromFilter(item)
                  }}
                >
                  <strong>{dataAllColumns[item].human_readable_name}: </strong>
                  {type && <span>[{type.toUpperCase()}] </span>}
                  <span>{tagValue || null}</span>
                </Tag>
              )
            }
            const tagValue = value.join(', ')
            return (
              <Tag
                key={item}
                closable
                onClose={(e: any) => {
                  e.stopPropagation()
                  removeFromFilter(item)
                }}
              >
                <strong>{dataAllColumns[item]?.human_readable_name}: </strong>
                <Tooltip title={tagValue} key={tagValue}>
                  <span>{tagValue.length > 50 ? `${tagValue.slice(0, 50)}...` : tagValue || null}</span>
                </Tooltip>
              </Tag>
            )
          })}
          {Object.keys(filteredInfo).filter((item) => !!filteredInfo[item as keyof FilterInfoColumn]).length > 1 && (
            <Button type="link" onClick={handleClearAllFilter}>
              Clear All
            </Button>
          )}
        </div>

        <Table
          rowKey="fund_id"
          dataSource={fundData?.funds}
          loading={loading || allColumnLoading || !columns.length}
          pagination={false}
          onChange={(_pagination, _filter: any, sorter: any) => {
            handleTableChange(sorter)
          }}
          size="small"
          scroll={{ y: 'calc(100vh - 468px)', x: 'min-content' }}
          rowSelection={{
            selectedRowKeys: selectedFundIds,
            onChange: (_, selectedRows) => {
              setSelectedFundIds(selectedRows.map((row) => row.fund_id))
              if (selectedRows.length === 1) {
                setSelectedFundName(selectedRows[0].fund_name)
              }
              setSelectedFundsAddFavorited(
                selectedRows.map((row) => ({ fund_id: row.fund_id, fund_name: row.fund_name })),
              )
            },
          }}
          footer={(record) =>
            record.length > 0 ? (
              <Footer>
                <FooterLeft>
                  <Space>
                    <Button.Group>
                      <Button onClick={handleSelectAll}>Select All</Button>
                      <Button onClick={handleClearAll}>Clear All</Button>
                      <Dropdown
                        trigger={['click']}
                        overlay={renderAddToList}
                        disabled={!selectedFundIds || selectedFundIds.length === 0}
                      >
                        <Button type="primary">Add To...</Button>
                      </Dropdown>
                      <Button
                        disabled={!selectedFundIds || selectedFundIds.length === 0}
                        type="primary"
                        style={{ marginLeft: '0.5rem' }}
                        onClick={() => setShowUpdateBenchmark(true)}
                      >
                        Bulk Update
                      </Button>
                    </Button.Group>
                    {user?.can_delete_fund && (
                      <Button danger onClick={() => setShowDeleteFund(true)} disabled={selectedFundIds?.length !== 1}>
                        Delete
                      </Button>
                    )}
                    <Button type="primary" onClick={exportFundSearch} loading={loadingExport}>
                      Export
                    </Button>
                    {showAddToGroup && selectedFundIds && (
                      <AnalysisGroupListProvider>
                        <AddToModal
                          selectedFunds={selectedFundIds}
                          isOpen={showAddToGroup}
                          onRequestClose={() => setShowAddToGroup(false)}
                        />
                      </AnalysisGroupListProvider>
                    )}
                    {showAddToIndex && selectedFundIds && (
                      <AddToIndexModal
                        selectedFunds={selectedFundIds}
                        isOpen={showAddToIndex}
                        onRequestClose={() => setShowAddToIndex(false)}
                      />
                    )}
                    {showAddToFavorited && selectedFundsAddFavorited && (
                      <AddToFavoritedModal
                        onChangeFavorited={onChangeFavorited}
                        selectedFundsAddFavorited={selectedFundsAddFavorited}
                        isOpen={showAddToFavorited}
                        onRequestClose={() => setShowAddToFavorited(false)}
                      />
                    )}
                    {showUpdateBenchmark && selectedFundIds && (
                      <UpdateBenchmarkModal
                        selectedFunds={selectedFundIds}
                        isOpen={showUpdateBenchmark}
                        onRequestClose={() => setShowUpdateBenchmark(false)}
                      />
                    )}
                    {showDeleteFund && selectedFundIds && selectedFundName && selectedFundIds.length === 1 && (
                      <DeleteFundModal
                        selectedFund={selectedFundIds[0]}
                        selectedFundName={selectedFundName}
                        isOpen={showDeleteFund}
                        onRequestClose={() => setShowDeleteFund(false)}
                        onSubmitDeleting={() => {
                          setSelectedFundIds(undefined)
                          setSelectedFundName(undefined)
                          refetch()
                        }}
                      />
                    )}
                  </Space>
                </FooterLeft>
                <FooterRight>
                  <Pagination
                    showSizeChanger
                    size="small"
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    onChange={(currentPage) => setPage(currentPage)}
                    total={fundData ? fundData.total : 0}
                    current={page}
                    pageSize={pageSize}
                    pageSizeOptions={['10', '25', '50', '100']}
                    onShowSizeChange={handlePageSizeChange}
                  />
                </FooterRight>
              </Footer>
            ) : null
          }
          columns={
            columns && columns.length
              ? columns.map((item, columnIndex) => {
                  if (item.key === 'fund_id' || item.key === 'firm_id') {
                    return {
                      ...item,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      sorter: item.sorter,
                      fixed: columnIndex === 0,
                      width: item.width || 200,
                      ellipsis: true,
                      ...getColumnSearchProps(item.key, item.filter_type),
                    }
                  }
                  if (item.key === 'fund_name') {
                    return {
                      ...item,
                      sorter: true,
                      width: 200,
                      fixed: columnIndex === 0,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                      onCell: (record: FundSearchColumnResponse) => ({
                        onClick: () => {
                          dispatch(
                            addTabs({ name: record.fund_name, id: record.fund_id, type: FundFirmTypeEnum.FUNDS }),
                          )
                        },
                      }),
                    }
                  } else if (item.key === 'firm_name') {
                    return {
                      ...item,
                      sorter: true,
                      width: 200,
                      fixed: columnIndex === 0,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                      onCell: (record: FundSearchColumnResponse) => ({
                        onClick: () => {
                          dispatch(
                            addTabs({
                              name: record.firm_name,
                              id: record.firm_id,
                              type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                            }),
                          )
                        },
                      }),
                    }
                  }
                  if (item.key === 'firm_assets') {
                    return {
                      ...item,
                      width: 150,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (text: number, record: FundSearchColumnResponse) => (
                        <FundFirmName>
                          {text
                            ? Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: record.firm_denomination || 'USD',
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                              }).format(text)
                            : ''}
                        </FundFirmName>
                      ),
                      onCell: (record: FundSearchColumnResponse) => ({
                        onClick: () => {
                          dispatch(
                            addTabs({
                              name: record.firm_name,
                              id: record.firm_id,
                              type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                              path: LeftNavFirmEnum.FIRM_ASSETS,
                            }),
                          )
                        },
                      }),
                    }
                  }
                  if (item.key === 'fund_assets') {
                    return {
                      ...item,
                      width: 150,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (text: number, record: FundSearchColumnResponse) => (
                        <FundFirmName>
                          {text && record.denomination
                            ? Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: record.denomination,
                                maximumFractionDigits: 3,
                                minimumFractionDigits: 3,
                              }).format(text)
                            : text
                            ? text.toFixed(3)
                            : ''}
                        </FundFirmName>
                      ),
                      onCell: (record: FundSearchColumnResponse) => ({
                        onClick: () => {
                          dispatch(
                            addTabs({
                              name: record.fund_name,
                              id: record.fund_id,
                              type: FundFirmTypeEnum.FUNDS,
                              path: LeftNavFundEnum.PERFORMANCE,
                              hyperlinkTable: item.key,
                            }),
                          )
                        },
                      }),
                    }
                  }
                  if (item.key === 'fund_assets_date') {
                    return {
                      ...item,
                      sorter: true,
                      width: 200,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (text: number) => <FundFirmName>{text}</FundFirmName>,
                      onCell: (record: FundSearchColumnResponse) => ({
                        onClick: () => {
                          dispatch(
                            addTabs({
                              name: record.fund_name,
                              id: record.fund_id,
                              type: FundFirmTypeEnum.FUNDS,
                              path: LeftNavFundEnum.PERFORMANCE,
                              hyperlinkTable: item.key,
                            }),
                          )
                        },
                      }),
                    }
                  }
                  if (item.key === 'latest_ror' || item.key === 'latest_ror_date') {
                    return {
                      ...item,
                      sorter: true,
                      width: 200,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (text: number) => <FundFirmName>{text}</FundFirmName>,
                      onCell: (record: FundSearchColumnResponse) => ({
                        onClick: () => {
                          dispatch(
                            addTabs({
                              name: record.fund_id,
                              id: record.fund_id,
                              type: FundFirmTypeEnum.FUNDS,
                              path: LeftNavFundEnum.PERFORMANCE,
                              hyperlinkTable: item.key,
                            }),
                          )
                        },
                      }),
                    }
                  }
                  if (item.key === 'is_fund_esg') {
                    return {
                      ...item,
                      sorter: true,
                      width: 200,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                      onCell: (record: FundSearchColumnResponse) => ({
                        onClick: () => {
                          dispatch(
                            addTabs({
                              name: record.fund_name,
                              id: record.fund_id,
                              type: FundFirmTypeEnum.FUNDS,
                              path: LeftNavFundEnum.FUND_ESG,
                              hyperlinkTable: 'fund_esg',
                            }),
                          )
                        },
                      }),
                    }
                  }
                  if (item.key === 'is_firm_esg') {
                    return {
                      ...item,
                      sorter: true,
                      width: 200,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                      onCell: (record: FundSearchColumnResponse) => ({
                        onClick: () => {
                          dispatch(
                            addTabs({
                              name: record.firm_name,
                              id: record.firm_id,
                              type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                              path: LeftNavFirmEnum.FIRM_ESG,
                              hyperlinkTable: 'firm_esg',
                            }),
                          )
                        },
                      }),
                    }
                  }
                  if (item.key === 'fund_cmic') {
                    return {
                      ...item,
                      sorter: true,
                      width: 200,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                      onCell: (record: FundSearchColumnResponse) => ({
                        onClick: () => {
                          dispatch(
                            addTabs({
                              name: record.fund_name,
                              id: record.fund_id,
                              type: FundFirmTypeEnum.FUNDS,
                              path: LeftNavFundEnum.EXECUTIVE_ORDERS,
                            }),
                          )
                        },
                      }),
                    }
                  }
                  if (item.key === 'firm_ccmc') {
                    return {
                      ...item,
                      sorter: true,
                      width: 200,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                      onCell: (record: FundSearchColumnResponse) => ({
                        onClick: () => {
                          dispatch(
                            addTabs({
                              name: record.firm_name,
                              id: record.firm_id,
                              type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                              path: LeftNavFirmEnum.EXECUTIVE_ORDERS,
                            }),
                          )
                        },
                      }),
                    }
                  }
                  if (item.filter_type === 'boolean') {
                    return {
                      ...item,
                      sorter: true,
                      width: 200,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (value: boolean | undefined) => {
                        if (value) {
                          return 'Yes'
                        }
                        if (value === false) {
                          return 'No'
                        }
                        return null
                      },
                    }
                  }
                  if (item.filter_type === 'year_month') {
                    return {
                      ...item,
                      sorter: true,
                      width: 200,
                      fixed: columnIndex === 0,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (date: string | undefined) => {
                        return date ? GenUtil.getFormattedYearAndMonth(date) : null
                      },
                    }
                  }
                  if (item.filter_type === 'date') {
                    return {
                      ...item,
                      sorter: true,
                      width: 200,
                      fixed: columnIndex === 0,
                      filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                      ...getColumnSearchProps(item.key, item.filter_type),
                      render: (date: string | undefined) => {
                        return date ? GenUtil.getFormattedYearMonthAndDay(date) : null
                      },
                    }
                  }
                  return {
                    ...item,
                    sorter: true,
                    width: 200,
                    fixed: columnIndex === 0,
                    filteredValue: filteredInfo[item.key as keyof FilterInfoColumn] || null,
                    ...getColumnSearchProps(item.key, item.filter_type),
                  }
                })
              : []
          }
        />
      </WrapperTable>
    </Wrapper>
  )
}
