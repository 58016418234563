import { ReloadOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, Radio, Row, Select, Space, Table, Tag } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import { updateExSearchParams } from '../../routes/Dashboard/DashboardTab/SearchForm/redux/searchParamsSlice'
import { ColumnPropsExtended } from '../../routes/ExternalDashboard/ExColumnBuilder/columnSource'
import { ModalFundActivation } from '../../routes/ExternalDashboard/ModalFundActivation'
import { addTabs, FundFirmTypeEnum, PREFIX_EXTERNAL } from '../../slice/appSettingsSlice'
import { FundDetailDropdownContext } from '../api/context/FundDetailDropdownContext'
import { ExFundDetails } from '../api/models/ExFundDetails'
import { FundDropdownCodeType, FundDropdownType } from '../api/models/FundDetails'
import { Colors } from '../colors'
import { LeftNavExFirmEnum } from '../SearchExFirmLabel'
import { LeftNavExFundEnum } from '../SearchExFundLabel'
import { LeftNavFirmEnum } from '../SearchFirmLabel'
import { LeftNavFundEnum } from '../SearchFundLabel'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-table-fixed-left .ant-table-body-inner {
    overflow-x: hidden;
  }

  .ant-table-container {
    border-bottom: solid 1px #f0f0f0;
  }

  .ant-table-footer {
    padding: 16px 0 0 0;
    background: none;
  }
`

const multiSelectField = [
  'fund_status',
  'regional_focus',
  'main_strategy',
  'sub_strategy',
  'is_fund_esg',
  'is_firm_esg',
]
const dateField = [
  'latest_ror_date',
  'latest_asset_date',
  'inception_date',
  'latest_firm_asset_date',
  'latest_nav_date',
]

const numbericField = ['latest_ror', 'latest_asset', 'latest_firm_asset', 'latest_nav']

const FundFirmName = styled('span')`
  color: ${Colors.primary};
  cursor: pointer;
`

export enum EXFUND_FIRM_TABLE {
  EXFUND,
  FIRM,
}

type Props = {
  type: EXFUND_FIRM_TABLE
  initialColumn: ColumnPropsExtended[]
  setActiveTab?: (value: LeftNavFirmEnum) => void
  dataSource: ExFundDetails[] | undefined
  loadingTable: boolean
  reloadTable?: () => void
  initialFilter?: Record<string, (string | number)[] | null>
}

const FundOfFirmTable: React.FC<Props> = ({
  type,
  initialColumn,
  setActiveTab,
  dataSource,
  loadingTable,
  reloadTable,
  initialFilter,
}) => {
  const dispatch = useDispatch()

  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const [showModalFundStatus, setShowModalFundStatus] = React.useState(false)
  const [selectedFund, setSelectedFund] = React.useState<ExFundDetails | undefined>()

  const [filteredInfo, setFilteredInfo] = React.useState(initialFilter || {})
  const appData = useSelector((state: RootState) => state.appData)

  React.useEffect(() => {
    if (type === EXFUND_FIRM_TABLE.EXFUND) {
      dispatch(updateExSearchParams(filteredInfo))
    }
  }, [dispatch, filteredInfo, type])

  const searchInput = React.useRef<Input>(null)

  const handleClearAllFilter = () => {
    setFilteredInfo({})
  }

  const getColumnSearchProps = (dataIndex: string): ColumnProps<ExFundDetails> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      if (dataIndex === 'fund_id' || dataIndex === 'firm_id') {
        return (
          <Row style={{ padding: 8 }}>
            <Space>
              <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0] ? selectedKeys[0].toString().split('-')[1] : undefined}
                allowClear
                onChange={(e) => {
                  setSelectedKeys(
                    e.target.value
                      ? [
                          selectedKeys[0]
                            ? `${selectedKeys[0].toString().split('-')[0]}-${e.target.value}`
                            : `-${e.target.value}`,
                        ]
                      : [],
                  )
                }}
              />
              <Button
                type="primary"
                onClick={() => {
                  if (selectedKeys[0]) {
                    setSelectedKeys([`[Equals]-${selectedKeys[0].toString().split('-')[1]}`])
                    return confirm && confirm()
                  }
                  return clearFilters && clearFilters()
                }}
              >
                Exact
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  if (selectedKeys[0]) {
                    setSelectedKeys([`[Contains]-${selectedKeys[0].toString().split('-')[1]}`])
                    return confirm && confirm()
                  }
                  return clearFilters && clearFilters()
                }}
              >
                Partial
              </Button>
              <Button onClick={() => clearFilters && clearFilters()} icon={<ReloadOutlined />} />
            </Space>
          </Row>
        )
      }
      if (multiSelectField.includes(dataIndex)) {
        let options: { label: string; value: string }[] = []
        switch (dataIndex) {
          case 'main_strategy':
            if (!appData?.strategies || !appData?.strategies.length) {
              return
            }
            options = appData.strategies.map((item) => ({
              value: item.strategy_name.toString(),
              label: item.strategy_name.toString(),
            }))
            break
          case 'sub_strategy':
            if (!appData?.subStrategies || !appData?.subStrategies.length) {
              return
            }
            const uniqueSubStrategy = new Set(
              appData.subStrategies.map((item) => {
                return item.sub_strategy_name
              }),
            )
            uniqueSubStrategy.forEach((item) => options.push({ value: item, label: item }))
            break
          case 'fund_status':
            if (!fundDataDropdown?.fund_status_code || !fundDataDropdown.fund_status_code.length) {
              return
            }
            options = fundDataDropdown.fund_status_code.map((item) => ({
              value: item.code_description,
              label: item.code_description,
            }))
            break
          case 'regional_focus':
            if (!fundDataDropdown?.region_inv_focus || !fundDataDropdown.region_inv_focus.length) {
              return
            }
            options = fundDataDropdown.region_inv_focus.map((item) => ({
              value: item.code_description,
              label: item.code_description,
            }))
            break
          default:
            const optionsDropdown = (fundDataDropdown && fundDataDropdown[dataIndex as keyof FundDropdownType]) || []
            options = (optionsDropdown as Array<FundDropdownCodeType | string>).map((item) =>
              typeof item === 'string'
                ? { value: item, label: item }
                : { value: item.code_description, label: item.code_description },
            )
        }

        return (
          <Row style={{ padding: 8 }}>
            <Space align="start">
              <Select
                value={selectedKeys}
                style={{ width: 200 }}
                mode="multiple"
                allowClear
                onChange={(e) => setSelectedKeys(e as string[])}
                options={options}
              />
              <Button
                type="primary"
                onClick={() => {
                  if (selectedKeys.length === 0) {
                    clearFilters && clearFilters()
                  }
                  return confirm && confirm()
                }}
                icon={<SearchOutlined />}
              />
              <Button onClick={() => clearFilters && clearFilters()} icon={<ReloadOutlined />} />
            </Space>
          </Row>
        )
      }
      if (numbericField.includes(dataIndex)) {
        return (
          <div style={{ padding: 8 }} tabIndex={0}>
            <Input
              placeholder={`GTE ${dataIndex}`}
              value={
                selectedKeys[0] && (selectedKeys[0] as string).split('-')[0] !== 'N/A'
                  ? (selectedKeys[0] as string).split('-')[0]
                  : undefined
              }
              onChange={(e) => {
                if (e.target.value === '') {
                  setSelectedKeys([`N/A-${selectedKeys[0] ? (selectedKeys[0] as string).split('-')[1] : 'N/A'}`])
                } else {
                  setSelectedKeys([
                    `${e.target.value}-${selectedKeys[0] ? (selectedKeys[0] as string).split('-')[1] : 'N/A'}`,
                  ])
                }
              }}
              onPressEnter={() => {
                if (!selectedKeys[0] || (selectedKeys[0] as string).length === 1) {
                  return clearFilters && clearFilters()
                }
                return confirm && confirm()
              }}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
              type="number"
              min={0}
            />
            <Input
              placeholder={`LTE ${dataIndex}`}
              value={
                selectedKeys[0] && (selectedKeys[0] as string).split('-')[1] !== 'N/A'
                  ? (selectedKeys[0] as string).split('-')[1]
                  : undefined
              }
              onChange={(e) => {
                if (e.target.value === '') {
                  setSelectedKeys([`${selectedKeys[0] ? (selectedKeys[0] as string).split('-')[0] : 'N/A'}-N/A`])
                } else {
                  setSelectedKeys([
                    `${selectedKeys[0] ? (selectedKeys[0] as string).split('-')[0] : 'N/A'}-${e.target.value}`,
                  ])
                }
              }}
              onPressEnter={() => {
                if (selectedKeys[0].toString() === 'N/A-N/A') {
                  return clearFilters && clearFilters()
                }
                return confirm && confirm()
              }}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
              type="number"
              min={0}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  if (selectedKeys[0].toString() === 'N/A-N/A') {
                    return clearFilters && clearFilters()
                  }
                  return confirm && confirm()
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        )
      }
      if (dateField.includes(dataIndex)) {
        return (
          <div style={{ padding: 8 }}>
            <Row>
              <DatePicker.RangePicker
                picker="month"
                value={
                  selectedKeys[0]
                    ? [
                        moment((selectedKeys[0] as string).split(',')[0]),
                        moment((selectedKeys[0] as string).split(',')[1]),
                      ]
                    : undefined
                }
                onChange={(e) => {
                  if (e) {
                    setSelectedKeys([`${moment(e[0]).format('YYYY-MM')}, ${moment(e[1]).format('YYYY-MM')}`])
                  }
                }}
                style={{ width: 250, marginBottom: 8 }}
              />
            </Row>
            <Row>
              <Space>
                <Button
                  type="primary"
                  onClick={() => confirm && confirm()}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </Row>
          </div>
        )
      }
      if (dataIndex.includes('index_family_')) {
        return (
          <Row style={{ padding: 8 }}>
            <Radio.Group
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys([e.target.value])}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm && confirm()
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters && clearFilters()
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </Row>
        )
      }
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm && confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm && confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (dataIndex === 'fund_id' || dataIndex === 'firm_id') {
        const type = value.toString().split('-')[0]
        const fundValue = value.toString().split('-')[1]
        if (type === '[Equals]') {
          return record[dataIndex as keyof ExFundDetails]
            ? record[dataIndex as keyof ExFundDetails] === fundValue
            : false
        }
        return record[dataIndex as keyof ExFundDetails]
          ? record[dataIndex as keyof ExFundDetails]
              .toString()
              .toLowerCase()
              .includes(fundValue.toString().toLowerCase())
          : false
      }
      if (numbericField.includes(dataIndex)) {
        const gte = value.toString().split('-')[0]
        const lte = value.toString().split('-')[1]
        if (gte !== 'N/A') {
          return lte !== 'N/A'
            ? record[dataIndex as keyof ExFundDetails] >= parseFloat(gte) &&
                record[dataIndex as keyof ExFundDetails] <= parseFloat(lte)
            : record[dataIndex as keyof ExFundDetails] >= parseFloat(gte)
        } else {
          return record[dataIndex as keyof ExFundDetails] <= parseFloat(lte)
        }
      }
      if (dateField.includes(dataIndex)) {
        const dateRange = (value as string).split(',')
        const startDate = moment(dateRange[0])
        const endDate = moment(dateRange[1])
        return moment(record[dataIndex as keyof ExFundDetails] as string, 'YYYY-MM').isBetween(
          startDate,
          endDate,
          undefined,
          '[]',
        )
      }
      if (dataIndex === 'fund_status' || dataIndex === 'fund_esg') {
        return record[dataIndex as keyof ExFundDetails] === value // exact match
      }
      if (dataIndex.includes('index_family_')) {
        return record[dataIndex as keyof ExFundDetails] === value // boolean compare
      }
      return record[dataIndex as keyof ExFundDetails]
        ? record[dataIndex as keyof ExFundDetails].toString().toLowerCase().includes(value.toString().toLowerCase())
        : false
    },

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const sortColumn = (a: string | null, b: string | null) => {
    if (a && b) {
      return a.localeCompare(b)
    } else if (a && !b) {
      return -1
    } else if (!a && b) {
      return 1
    } else {
      return 0
    }
  }
  const sortColumnNumber = (a: number | null, b: number | null) => {
    if (a && b) {
      return a - b
    } else if (a && !b) {
      return -1
    } else if (!a && b) {
      return 1
    } else {
      return 0
    }
  }

  return (
    <Wrapper>
      <div style={{ marginBottom: '1rem' }}>
        {Object.keys(filteredInfo).map((item) => {
          const value = filteredInfo[item]
          if (value !== null) {
            if (typeof value[0] === 'boolean') {
              return (
                <Tag
                  key={item}
                  closable
                  onClose={(e: any) => {
                    e.stopPropagation()
                    setFilteredInfo({ ...filteredInfo, [item]: null })
                  }}
                >
                  <strong>{initialColumn.find((i) => i.key === item)?.title}: </strong>
                  <span>{value[0] === true ? 'Yes' : 'No'}</span>
                </Tag>
              )
            }
            return (
              <Tag
                key={item}
                closable
                onClose={(e: any) => {
                  e.stopPropagation()
                  setFilteredInfo({ ...filteredInfo, [item]: null })
                }}
              >
                <strong>{initialColumn.find((i) => i.key === item)?.title}</strong>: {filteredInfo[item]}
              </Tag>
            )
          }
        })}
        {Object.keys(filteredInfo).some((item) => filteredInfo[item]) && (
          <Button type="link" onClick={handleClearAllFilter}>
            Clear All
          </Button>
        )}
      </div>
      <Table
        size="small"
        rowKey="fund_id"
        loading={loadingTable}
        dataSource={dataSource}
        pagination={{
          defaultPageSize: 10,
        }}
        onChange={(_pagination, filter) => {
          setFilteredInfo(filter)
        }}
        scroll={{ x: 'min-content' }}
        columns={initialColumn.map((item, columnIndex) => {
          if (type === EXFUND_FIRM_TABLE.FIRM) {
            if (item.key.includes('index_family_')) {
              return {
                ...item,
                sorter: true,
                width: 200,
                filteredValue: filteredInfo[item.key] || null,
                ...getColumnSearchProps(item.key),
                render: (value: boolean | undefined) => {
                  if (value === undefined) {
                    return null
                  }
                  return value ? 'Yes' : 'No'
                },
              }
            }
            switch (item.key) {
              case 'fund_name':
                return {
                  ...item,
                  filteredValue: filteredInfo[item.key] || null,
                  sorter: (a: ExFundDetails, b: ExFundDetails) => {
                    return sortColumn(
                      String(a[item.key as keyof ExFundDetails]),
                      String(b[item.key as keyof ExFundDetails]),
                    )
                  },
                  width: 280,
                  fixed: columnIndex === 0,
                  ...getColumnSearchProps(item.key),
                  render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                  onCell: (record: ExFundDetails) => ({
                    onClick: () => {
                      dispatch(
                        addTabs({
                          name: record.fund_name,
                          id: record.fund_id,
                          type: FundFirmTypeEnum.FUNDS,
                        }),
                      )
                    },
                  }),
                }
              case 'is_fund_esg':
                return {
                  ...item,
                  sorter: true,
                  width: 200,
                  filteredValue: filteredInfo[item.key] || null,
                  ...getColumnSearchProps(item.key),
                  render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                  onCell: (record: ExFundDetails) => ({
                    onClick: () => {
                      dispatch(
                        addTabs({
                          name: record.fund_name,
                          id: record.fund_id,
                          type: FundFirmTypeEnum.FUNDS,
                          path: LeftNavFundEnum.FUND_ESG,
                          hyperlinkTable: 'fund_esg',
                        }),
                      )
                    },
                  }),
                }
              case 'is_firm_esg':
                return {
                  ...item,
                  filteredValue: filteredInfo[item.key] || null,
                  sorter: (a: ExFundDetails, b: ExFundDetails) => {
                    return sortColumn(
                      String(a[item.key as keyof ExFundDetails]),
                      String(b[item.key as keyof ExFundDetails]),
                    )
                  },
                  width: 200,
                  ...getColumnSearchProps(item.key),
                  render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                  onCell: () => ({
                    onClick: () => setActiveTab && setActiveTab(LeftNavFirmEnum.FIRM_ESG),
                  }),
                }

              default:
                return {
                  ...item,
                  filteredValue: filteredInfo[item.key] || null,
                  sorter: (a: ExFundDetails, b: ExFundDetails) => {
                    return sortColumn(
                      String(a[item.key as keyof ExFundDetails]),
                      String(b[item.key as keyof ExFundDetails]),
                    )
                  },
                  width: item.key === 'fund_id' ? 120 : 200,
                  ...getColumnSearchProps(item.key),
                }
            }
          } else {
            switch (item.key) {
              case 'fund_name':
                return {
                  ...item,
                  filteredValue: filteredInfo[item.key] || null,
                  sorter: (a: ExFundDetails, b: ExFundDetails) => {
                    return sortColumn(
                      String(a[item.key as keyof ExFundDetails]),
                      String(b[item.key as keyof ExFundDetails]),
                    )
                  },
                  width: 280,
                  fixed: columnIndex === 0,
                  ...getColumnSearchProps(item.key),
                  render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                  onCell: (record: ExFundDetails) => ({
                    onClick: () => {
                      dispatch(
                        addTabs({
                          name: record.fund_name,
                          id: `${PREFIX_EXTERNAL}-${record.fund_id}`,
                          type: FundFirmTypeEnum.FUNDS,
                        }),
                      )
                    },
                  }),
                }

              case 'is_fund_esg':
                return {
                  ...item,
                  filteredValue: filteredInfo[item.key] || null,
                  sorter: (a: ExFundDetails, b: ExFundDetails) => {
                    return sortColumn(
                      String(a[item.key as keyof ExFundDetails]),
                      String(b[item.key as keyof ExFundDetails]),
                    )
                  },
                  width: 200,
                  ...getColumnSearchProps(item.key),
                  render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                  onCell: (record: ExFundDetails) => ({
                    onClick: () => {
                      dispatch(
                        addTabs({
                          name: record.fund_name,
                          id: `${PREFIX_EXTERNAL}-${record.fund_id}`,
                          type: FundFirmTypeEnum.FUNDS,
                          activeNav: LeftNavExFundEnum.FUND_ESG_FACTORS,
                        }),
                      )
                    },
                  }),
                }
              case 'is_firm_esg':
                return {
                  ...item,
                  filteredValue: filteredInfo[item.key] || null,
                  sorter: (a: ExFundDetails, b: ExFundDetails) => {
                    return sortColumn(
                      String(a[item.key as keyof ExFundDetails]),
                      String(b[item.key as keyof ExFundDetails]),
                    )
                  },
                  width: 200,
                  ...getColumnSearchProps(item.key),
                  render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                  onCell: (record: ExFundDetails) => ({
                    onClick: () => {
                      dispatch(
                        addTabs({
                          name: record.firm_name,
                          id: `${PREFIX_EXTERNAL}-${record.firm_id}`,
                          type: FundFirmTypeEnum.FIRMS,
                          activeNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
                        }),
                      )
                    },
                  }),
                }
              case 'latest_ror':
              case 'latest_nav':
              case 'latest_asset':
                return {
                  ...item,
                  filteredValue: filteredInfo[item.key] || null,
                  sorter: (a: ExFundDetails, b: ExFundDetails) => {
                    return sortColumnNumber(
                      Number(a[item.key as keyof ExFundDetails]) || null,
                      Number(b[item.key as keyof ExFundDetails]) || null,
                    )
                  },
                  width: 200,
                  ...getColumnSearchProps(item.key),
                  render: (text: string) => (
                    <FundFirmName>
                      {item.key === 'latest_ror'
                        ? text && parseFloat(text).toFixed(4)
                        : text && parseFloat(text).toFixed(3)}
                    </FundFirmName>
                  ),
                  onCell: (record: ExFundDetails) => ({
                    onClick: () => {
                      dispatch(
                        addTabs({
                          name: record.fund_name,
                          id: `${PREFIX_EXTERNAL}-${record.fund_id}`,
                          type: FundFirmTypeEnum.FUNDS,
                          activeNav: LeftNavExFundEnum.FUND_PERFORMANCE,
                          hyperlinkTable: item.key,
                        }),
                      )
                    },
                  }),
                }
              case 'latest_firm_asset':
                return {
                  ...item,
                  filteredValue: filteredInfo[item.key] || null,
                  sorter: (a: ExFundDetails, b: ExFundDetails) => {
                    return sortColumnNumber(
                      Number(a[item.key as keyof ExFundDetails]) || null,
                      Number(b[item.key as keyof ExFundDetails]) || null,
                    )
                  },
                  width: 200,
                  ...getColumnSearchProps(item.key),
                  render: (text: string) => <FundFirmName>{text && parseFloat(text).toFixed(3)}</FundFirmName>,
                  onCell: (record: ExFundDetails) => ({
                    onClick: () => {
                      dispatch(
                        addTabs({
                          name: record.firm_name,
                          id: `${PREFIX_EXTERNAL}-${record.firm_id}`,
                          type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                          activeNav: LeftNavExFirmEnum.ASSETS_UNDER_MANAGEMENT,
                        }),
                      )
                    },
                  }),
                }
              case 'fund_status':
                return {
                  ...item,
                  filteredValue: filteredInfo[item.key] || null,
                  sorter: (a: ExFundDetails, b: ExFundDetails) => {
                    return sortColumn(
                      String(a[item.key as keyof ExFundDetails]),
                      String(b[item.key as keyof ExFundDetails]),
                    )
                  },
                  width: 200,
                  ...getColumnSearchProps(item.key),
                  render: (text: string) => <FundFirmName>{text}</FundFirmName>,
                  onCell: (record: ExFundDetails) => ({
                    onClick: () => {
                      setSelectedFund(record)
                      setShowModalFundStatus(true)
                    },
                  }),
                }
              case 'fund_cmic':
                return {
                  ...item,
                  width: 140,
                  render: (text) => {
                    return <FundFirmName>{text}</FundFirmName>
                  },
                  onCell: (record: ExFundDetails) => ({
                    onClick: () => {
                      dispatch(
                        addTabs({
                          name: record.fund_name,
                          id: `${PREFIX_EXTERNAL}-${record.fund_id}`,
                          type: FundFirmTypeEnum.FUNDS,
                          activeNav: LeftNavExFundEnum.FUND_CMIC,
                        }),
                      )
                    },
                  }),
                }

              default:
                return {
                  ...item,
                  filteredValue: filteredInfo[item.key] || null,
                  sorter: (a: ExFundDetails, b: ExFundDetails) => {
                    return sortColumn(
                      String(a[item.key as keyof ExFundDetails]),
                      String(b[item.key as keyof ExFundDetails]),
                    )
                  },
                  width: item.key === 'fund_id' ? 120 : 200,
                  ...getColumnSearchProps(item.key),
                }
            }
          }
        })}
      />
      {showModalFundStatus && selectedFund && reloadTable && (
        <ModalFundActivation
          showModal={showModalFundStatus}
          setShowModal={setShowModalFundStatus}
          selectedFund={selectedFund}
          setSelectedFund={setSelectedFund}
          updateListFund={reloadTable}
        />
      )}
    </Wrapper>
  )
}

export default FundOfFirmTable
