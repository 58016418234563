import React, { Dispatch, SetStateAction } from 'react'
import APIService from '..'
import { useServiceState } from '../../hooks/useServiceState'
import { ExFirmDetails, MultiManagerPlatformType } from '../models/FirmDetails'
import { FundBenchmark, FundDropdownType } from '../models/FundDetails'
import { MinorityType } from '../services/minority.service'

export type MMPFirmType = {
  id: string
  name: string
  funds: MultiManagerPlatformType[]
}

export type FundDetailDropdownContextType = {
  fundDataDropdown?: FundDropdownType
  loading?: boolean
  dataFirms?: ExFirmDetails[]
  dataBenchmark?: FundBenchmark[]
  refetchFirm: () => void
  setFirmIdChoose: Dispatch<SetStateAction<string | undefined>>
  firmIdChoose?: string
  dataMinoritySearch?: MinorityType[]
}

const defaultValue: FundDetailDropdownContextType = {
  fundDataDropdown: undefined,
  loading: true,
  dataFirms: undefined,
  firmIdChoose: undefined,
  dataBenchmark: undefined,
  refetchFirm: () => undefined,
  setFirmIdChoose: () => undefined,
  dataMinoritySearch: undefined,
}
export const FundDetailDropdownContext = React.createContext<FundDetailDropdownContextType>(defaultValue)
type Props = {
  isExternal?: boolean
}
export const FundDetailDropdownProvider: React.FC<Props> = ({ isExternal, children }) => {
  const [firmIdChoose, setFirmIdChoose] = React.useState<string | undefined>(undefined)
  //
  const { invoke: getDataDropdown, data: fundDataDropdown, loading } = useServiceState(
    APIService.fundDetailService.fetchDataDropdown,
  )
  const { invoke: getMinoritySearch, data: dataMinoritySearch } = useServiceState(
    APIService.fundService.fetchFundSearchMinorityType,
  )
  const { invoke: getAllFirms, data: dataFirms } = useServiceState(APIService.firmDetailService.fetchAllFirms)

  const { invoke: getAllBenchmark, data: dataBenchmark } = useServiceState(
    APIService.fundDetailService.fetchFundBenchmark,
  )
  //
  const refetchFirm = () => {
    getAllFirms()
  }

  //
  React.useEffect(() => {
    getMinoritySearch()
  }, [getMinoritySearch])

  React.useEffect(() => {
    if (!dataFirms) {
      return
    }
    setFirmIdChoose(dataFirms[0].firm_id)
  }, [dataFirms])

  React.useEffect(() => {
    getAllFirms()
  }, [getAllFirms])

  React.useEffect(() => {
    if (isExternal) {
      return
    }
    getAllBenchmark()
  }, [getAllBenchmark, isExternal])

  React.useEffect(() => {
    getDataDropdown()
  }, [getDataDropdown])

  const value = {
    fundDataDropdown,
    loading,
    dataFirms,
    dataBenchmark,
    refetchFirm,
    firmIdChoose,
    setFirmIdChoose,
    dataMinoritySearch,
  }
  return <FundDetailDropdownContext.Provider value={value}>{children}</FundDetailDropdownContext.Provider>
}
