import React from 'react'
import styled from 'styled-components'
import { FirmDetailDataContext } from '../../../shared/api/context/FirmDetailDataContext'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import APIService from '../../../shared/api'
import { FundFamilyTable, FundFamilyType } from '../../../shared/components/FundFamilyTable'
import { RootState } from '../../../app/rootReducer'
import { useSelector } from 'react-redux'

const Flex = styled.div`
  flex: 1;
  display: flex;
`

type Props = {}
export const ExFirmFundFamily: React.FC<Props> = () => {
  const { dataFirmDetail, fundDataDropdown } = React.useContext(FirmDetailDataContext)

  const { invoke: getFundOfFirm, data: dataFundsFirm, loading } = useServiceState(
    APIService.firmDetailService.fetchFundsOfFirm,
  )

  const { strategies: strategyOptions, subStrategies: subStrategyOptions } = useSelector(
    (state: RootState) => state.appData,
  )
  // map fund family data
  const fundFamilyData = React.useMemo<FundFamilyType[]>(() => {
    console.log(dataFundsFirm, fundDataDropdown, strategyOptions, subStrategyOptions)
    if (!dataFundsFirm || !fundDataDropdown || !strategyOptions || !subStrategyOptions) {
      return []
    }
    const results = dataFundsFirm.map((d) => {
      //
      const stategy_op = d.strategy_code ? strategyOptions.find((s) => s.strategy_code === d.strategy_code) : null
      const stategy_code = stategy_op?.strategy_name
      //
      const sub_stategy_op = d.sub_strategy_code
        ? subStrategyOptions.find((s) => s.sub_strategy_code === d.sub_strategy_code)
        : null
      const sub_stategy_code = sub_stategy_op?.sub_strategy_name
      //
      const region_inv_focus_op = d.region_inv_focus
        ? fundDataDropdown.region_inv_focus.find((s) => s.code_code === `${d.region_inv_focus}`)
        : null
      const region_inv_focus = region_inv_focus_op?.code_description
      //
      const region_inv_focus_country_op = d.region_inv_focus_country
        ? fundDataDropdown.region_inv_focus_country.find((s) => s.code_code === `${d.region_inv_focus_country}`)
        : null
      const region_inv_focus_country = region_inv_focus_country_op?.code_description
      //
      return {
        fund_id: d.fund_id,
        fund_name: d.fund_name,
        firm_id: d.firm_id,
        firm_name: d.firm_name,
        fund_family_id: d.fund_family_id,
        fund_family_code: d.fund_family_code,
        strategy_code: stategy_code,
        sub_strategy_code: sub_stategy_code,
        region_inv_focus,
        region_inv_focus_country,
      } as FundFamilyType
    })

    return results
  }, [dataFundsFirm, strategyOptions, subStrategyOptions, fundDataDropdown])

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFundOfFirm(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFundOfFirm])

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <FundFamilyTable version="firm" editable={false} isLoading={loading} fundFamilyData={fundFamilyData} />
    </Flex>
  )
}
