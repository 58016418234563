export enum LeftNavFirmEnum {
  MAIN_INFORMATION = 'Main Information',
  FIRM_ASSETS = 'Firm Assets',
  FIRM_REG_INFORMATION = 'Firm Reg. Information',
  CODE_OF_CONDUCT = 'Code of Conduct',
  CO_INVESTMENTS = 'Co-Investments',
  FIRM_ESG = 'Firm ESG',
  FIRM_DIVERSITY_INCLUSION = 'Firm Diversity & Inclusion',
  FUNDS_OF_FIRM = 'Funds of Firm',
  DUE_DILIGENCE = 'Due Diligence',
  EXECUTIVE_ORDERS = 'Executive Orders',
  FIRM_MULTI_MANAGER_POD_PLATFORM = `Firm Multi-Manager "Pod" Platform`,
  FUND_FAMILY = 'Fund Family',
}

export enum RightTabFirmEnum {
  FIRM_INFO = 'Firm Info',
  CONTACT_INFORMATION = 'Contact Information',
  OWNERSHIP_DESCRIPTION = 'Ownership Description',
  SEC_INFO = 'SEC Info',
  NON_SEC_INFO = 'Non SEC Info',
}

export enum CollapseFirmEnum {}
export enum tabInCollapseFirmEnum {}

export interface ListSearchFirmType {
  key: string
  name: string
  leftNav: LeftNavFirmEnum
  rightTab?: RightTabFirmEnum
  collapse?: CollapseFirmEnum
  tabInCollapse?: tabInCollapseFirmEnum
}
export const renderSearchListFirm: ListSearchFirmType[] = [
  {
    key: 'firm_name',
    name: 'Firm Name',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'founded',
    name: 'Year Founded',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'firm_asset',
    name: 'Firm Assets',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: '%_of_owned_by_employees',
    name: '% of owned by employees',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'MMasof',
    name: 'MM as of',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'Registration',
    name: 'Registration',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'ofAnalyst',
    name: 'of Analyst',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: '#_of_Managers_Gained',
    name: '# of Managers Gained',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: '#_of_employees_w/ownership_stake',
    name: '# of employees w/ ownership stake',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'denomination',
    name: 'Denom.',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'Is_Firm_ESG?',
    name: 'Is Firm ESG?',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'Firm_AUM_Dedicated_to_HF',
    name: 'Firm AUM Dedicated to HF',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'as_of_1',
    name: 'as of',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'ESG_Receive_Guidance',
    name: 'ESG Receive Guidance',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'Firm_AUM_Dedicated_to_FOF',
    name: 'Firm AUM Dedicated to FOF',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'as_of_2',
    name: 'as of',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'ESG_3rd_Party_Guidance',
    name: 'ESG 3rd Party Guidance',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: '#_of_Managers_Lost',
    name: '# of Managers Lost',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'Ptf.Mgr.',
    name: 'Ptf.Mgr.',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'is_minority_firm',
    name: 'Is Minority Firm',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'Minority_Own_Level',
    name: 'Minority Own Level',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'minority_own_type',
    name: 'Minority Own Type',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.FIRM_INFO,
  },
  {
    key: 'contact_info_name',
    name: 'Name',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'contact_info_company',
    name: 'Company',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'contact_info_address',
    name: 'Address',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'contact_info_city',
    name: 'City',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'contact_info_postal_code',
    name: 'Postal Code',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'contact_info_phone',
    name: 'Phone',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'contact_info_state',
    name: 'State',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'contact_info_country',
    name: 'Country',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'contact_info_fax',
    name: 'Fax',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'contact_info_email',
    name: 'Email',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'contact_info_web',
    name: 'Web',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'contact_info_notes',
    name: 'HFR Notes',
    leftNav: LeftNavFirmEnum.MAIN_INFORMATION,
    rightTab: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'firm_reg_registered',
    name: 'Registered',
    leftNav: LeftNavFirmEnum.FIRM_REG_INFORMATION,
    rightTab: RightTabFirmEnum.SEC_INFO,
  },
  {
    key: 'firm_reg_SEC_Number',
    name: 'SEC Number',
    leftNav: LeftNavFirmEnum.FIRM_REG_INFORMATION,
    rightTab: RightTabFirmEnum.SEC_INFO,
  },
  {
    key: 'firm_reg_IARD_CRD_Number',
    name: 'IARD-CRD Number',
    leftNav: LeftNavFirmEnum.FIRM_REG_INFORMATION,
    rightTab: RightTabFirmEnum.SEC_INFO,
  },
  {
    key: 'firm_reg_Org.Type',
    name: 'Org. Type',
    leftNav: LeftNavFirmEnum.FIRM_REG_INFORMATION,
    rightTab: RightTabFirmEnum.SEC_INFO,
  },
  {
    key: 'firm_reg_country',
    name: 'Country',
    leftNav: LeftNavFirmEnum.FIRM_REG_INFORMATION,
    rightTab: RightTabFirmEnum.SEC_INFO,
  },
  {
    key: 'firm_reg_state',
    name: 'State',
    leftNav: LeftNavFirmEnum.FIRM_REG_INFORMATION,
    rightTab: RightTabFirmEnum.SEC_INFO,
  },
  {
    key: 'firm_reg_Exempt_Reason',
    name: 'Exempt. Reason',
    leftNav: LeftNavFirmEnum.FIRM_REG_INFORMATION,
    rightTab: RightTabFirmEnum.SEC_INFO,
  },
  {
    key: 'non_sec_info_country',
    name: 'Country',
    leftNav: LeftNavFirmEnum.FIRM_REG_INFORMATION,
    rightTab: RightTabFirmEnum.NON_SEC_INFO,
  },
  {
    key: 'non_sec_info_Reg_Authority',
    name: 'Reg Authority',
    leftNav: LeftNavFirmEnum.FIRM_REG_INFORMATION,
    rightTab: RightTabFirmEnum.NON_SEC_INFO,
  },
  {
    key: 'non_sec_info_Reg_Auth_TypeNo',
    name: 'Reg Auth Type No.',
    leftNav: LeftNavFirmEnum.FIRM_REG_INFORMATION,
    rightTab: RightTabFirmEnum.NON_SEC_INFO,
  },
  {
    key: 'non_sec_info_Reg_Auth_Type',
    name: 'Reg Auth Type',
    leftNav: LeftNavFirmEnum.FIRM_REG_INFORMATION,
    rightTab: RightTabFirmEnum.NON_SEC_INFO,
  },
  {
    key: 'firm_co_investment_Typical_Deal_Size',
    name: 'Typical Deal Size',
    leftNav: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    key: 'firm_co_investment_Typical_Liquity',
    name: 'Typical Liquity',
    leftNav: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    key: 'firm_co_investment_Private_Equity',
    name: 'Private Equity',
    leftNav: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    key: 'firm_co_investment_Public_Equity',
    name: 'Public Equity',
    leftNav: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    key: 'firm_co_investment_Private_Debt',
    name: 'Private Debt',
    leftNav: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    key: 'firm_co_investment_Public_Debt',
    name: 'Public Debt',
    leftNav: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    key: 'firm_co_investment_Other',
    name: 'Other',
    leftNav: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    key: 'firm_co_investment_Commingled_Fund',
    name: 'Commingled Fund',
    leftNav: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    key: 'firm_co_investment_Fund_of_One',
    name: 'Fund of One',
    leftNav: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    key: 'firm_co_investment_Separate_Managed_Acct',
    name: 'Separate Managed Acct',
    leftNav: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    key: 'firm_co_investment_Other_2',
    name: 'Other',
    leftNav: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    key: 'is_firm_esg',
    name: 'Does Your Firm Have A Formal Firmwide ESG Policy?',
    leftNav: LeftNavFirmEnum.FIRM_ESG,
  },
  {
    key: 'applied_firmculture',
    name: 'Firm Culture',
    leftNav: LeftNavFirmEnum.FIRM_ESG,
  },
  {
    key: 'applied_investmentprocess',
    name: 'Investment Process',
    leftNav: LeftNavFirmEnum.FIRM_ESG,
  },
  {
    key: 'applied_portfolioconstruction',
    name: 'Portfolio Construction',
    leftNav: LeftNavFirmEnum.FIRM_ESG,
  },
  {
    key: 'applied_esgproducts',
    name: 'ESG Products',
    leftNav: LeftNavFirmEnum.FIRM_ESG,
  },
  {
    key: 'applied_esgintegration',
    name: 'ESG Integration',
    leftNav: LeftNavFirmEnum.FIRM_ESG,
  },
  {
    key: 'applied_esgactivist',
    name: 'Activist / Engaged Ownership',
    leftNav: LeftNavFirmEnum.FIRM_ESG,
  },
  {
    key: 'applied_diversityinclusion',
    name: 'Diversity And Inclusion',
    leftNav: LeftNavFirmEnum.FIRM_ESG,
  },
  {
    key: 'receive_guidance',
    name:
      'Does Your Firm Receive ESG Investment Guidance From Or Follow The Principles Of A Third-Party Company/Association/Network?',
    leftNav: LeftNavFirmEnum.FIRM_ESG,
  },
  {
    key: 'thirdparty_guidance',
    name: 'Identify The Third-Party Company',
    leftNav: LeftNavFirmEnum.FIRM_ESG,
  },
  {
    key: 'future',
    name: 'Do You Have Plans To Incorporate ESG In The Future?',
    leftNav: LeftNavFirmEnum.FIRM_ESG,
  },
]
