export enum LeftNavFundEnum {
  BASIC_INFO = 'Basic Information',
  PERFORMANCE = 'Performance',
  INFORMATION = 'Information',
  EXPORT_SETUP = 'Export Setup',
  DUE_DILIGENCE = 'Due Diligence',
  UNIVERSES = 'Universes',
  HFR_AM_INFO = 'HFR AM Info',
  FUND_ESG = 'Fund ESG',
  FUND_DIVERSITY_INCLUSION = 'Fund Diversity & Inclusion',
  FUND_PROPERTIES = 'Fund Properties',
  INDICES = 'Index Eligibility',
  EXECUTIVE_ORDERS = 'Executive Orders',
  FUND_FAMILY = 'Fund Family',
}
export enum RightTabFundEnum {
  ROR = 'ROR',
  NAV = 'NAV',
  ASSET = 'Asset',
  DISTRIBUTIONS = 'Distributions',
  APGL = 'APGL',
  PERFORMANCE_UPDATE = 'Performance Update',
  FUND_INFO = 'Fund Info',
  FUND_INVESTMENT_INFO = 'Fund Investment Info',
  OFFSHORE_FUND_INFO = 'Offshore Fund Info',
  FUND_CONTACT = 'Fund Contact',
  LEVERAGE = 'Leverage',
  INSTRUMENT = 'Instrument',
  INDUSTRY = 'Industry',
  INVESTOR_TYPE = 'Investor Type',
  REGION = 'Region',
  ELECTRONIC_TRADING_PLATFORM = 'Electronic Trading Platform',
  DOCUMENTS = 'Documents',
  ACTIVITIES = 'Activities',
  SEP_ACCT = 'Sep. Acct',
  CHECKLIST = 'Checklist',
  INVEST_OPINIONS = 'Invest. Opinions',
  SCORE_CARD = 'Score Card',
  CONTACTS = 'Contacts',
  MEETINGS = 'Meetings',
  MONTHLY = 'Monthly',
}

export enum CollapseFundEnum {
  MILIONS_MANAGED = 'Milions Managed',
  NAV_PER_UNIT = 'NAV Per Unit',
  INFORMATION = 'Information',
  STRUCTURE = 'Structure',
  FEES = 'Fees',
  PRINCIPALS = 'Principals',
  INVESTMENT_INFO = 'Investment Info',
  HFR_AM_INFO_NOTE = 'Notes',
  HFR_AM_INFO_ARCHIVE_NOTES = 'Archive Notes',

  STRATEGY_DESCRIPTION = 'Strategy Description',
  OFFSHORE_FUND_INFORMATION = 'Offshore Fund Information',
  FUND_ESG = 'Fund ESG',
  DESCRIBE_ESG_CRITERIA = 'Describe ESG Criteria',
  TEAM_AND_DATA = 'Team and Data',
}

export enum TabInCollapseFundEnum {
  ENVIRONMENTAL = 'Environmental',
  SOCIAL = 'Social',
  GOVERNANCE = 'Governance',
  TEAM = 'Team',
  DATA = 'Data',
}

export interface ListSearchFundType {
  key: string
  name: string
  leftNav: LeftNavFundEnum
  rightTab?: RightTabFundEnum
  collapse?: CollapseFundEnum
  tabInCollapse?: TabInCollapseFundEnum
}

const getFundIndexEligibilityDetailsOptions = (prefixName: string, label: string) => {
  return [
    {
      key: prefixName,
      name: `${label} (Index Tab)`,
      leftNav: LeftNavFundEnum.INDICES,
    },
    {
      key: `${prefixName}_index_eligibility`,
      name: `${label} Index Eligibility`,
      leftNav: LeftNavFundEnum.INDICES,
    },
    {
      key: `${prefixName}_last_confirmed_date`,
      name: `${label} Last Confirmed Date`,
      leftNav: LeftNavFundEnum.INDICES,
    },
    {
      key: `${prefixName}_confirmed_by`,
      name: `${label} Confirmed By`,
      leftNav: LeftNavFundEnum.INDICES,
    },
  ]
}

const getFundIndexEligibilityReviewOptions = (prefixName: string, label: string) => {
  return [
    {
      key: prefixName,
      name: `${label} (Index Tab)`,
      leftNav: LeftNavFundEnum.INDICES,
    },
    {
      key: `${prefixName}_reviewed_date`,
      name: `${label} Reviewed Date`,
      leftNav: LeftNavFundEnum.INDICES,
    },
    {
      key: `${prefixName}_reviewed_by`,
      name: `${label} Reviewed By`,
      leftNav: LeftNavFundEnum.INDICES,
    },
  ]
}

export const renderSearchListFund: ListSearchFundType[] = [
  {
    key: 'fund_name',
    name: 'Fund Name',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'firm_id',
    name: 'Firm Name',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfri_fund',
    name: 'HFRI Fund',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'strategy_code_new',
    name: 'Strategy',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'sub_strategy_code_new',
    name: 'Sub-Strategy',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'allocation',
    name: 'Allocation',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'style',
    name: 'Style',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'added_to_db',
    name: 'Added To DB',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfri_pending',
    name: 'HFRI Pending',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfri_500',
    name: 'HFRI 500',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfri_woman_access',
    name: 'HFRI Woman Access',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfri_i_fund',
    name: 'HFRI-I Fund',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfrx_fund',
    name: 'HFRX Fund',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfrb_fund',
    name: 'HFRB Fund',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfri_institutional',
    name: 'HFRI Institutional',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfru_fund',
    name: 'HFRU Fund',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfr_la_fund',
    name: 'HFR LA Fund',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfrr_parity',
    name: 'HFRRParity',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfrr_parity_i',
    name: 'HFRRParity-I',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'denomination',
    name: 'Denom.',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'fund_status_code',
    name: 'Fund Status',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'product_class',
    name: 'Product Class',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'bloomberg_ticker',
    name: 'Bloomberg Ticker',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'isin',
    name: 'ISIN',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'sec_pfid_number',
    name: 'SEC-PFID',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'liquidated_at',
    name: 'Liquidated at',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'data_source',
    name: 'Data Source',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'hfr_analyst',
    name: 'HFR Analyst',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'notes',
    name: 'Note',
    leftNav: LeftNavFundEnum.BASIC_INFO,
  },
  {
    key: 'benchmark',
    name: 'Benchmark',
    leftNav: LeftNavFundEnum.PERFORMANCE,
    rightTab: RightTabFundEnum.ROR,
  },
  {
    key: 'reporting_style',
    name: 'Reporting Style',
    leftNav: LeftNavFundEnum.PERFORMANCE,
    rightTab: RightTabFundEnum.ROR,
  },
  {
    key: 'reporting_frequency',
    name: 'Req. Freq',
    leftNav: LeftNavFundEnum.PERFORMANCE,
    rightTab: RightTabFundEnum.ROR,
  },

  {
    key: 'is_aum_distributed',
    name: 'Is AUM Distributed ?',
    leftNav: LeftNavFundEnum.PERFORMANCE,
    rightTab: RightTabFundEnum.ASSET,
  },
  {
    key: 'is_nav_distributed',
    name: 'Is NAV Distributed ?',
    leftNav: LeftNavFundEnum.PERFORMANCE,
    rightTab: RightTabFundEnum.NAV,
  },
  {
    key: 'fund_assets',
    name: 'Fund Assets',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'firm_assets',
    name: 'Firm Assets',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'fund_assets_date',
    name: 'Fund Asset Date',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'firm_assets_date',
    name: 'Firm Asset Date',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'om_date',
    name: 'OM Date',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'inception_date',
    name: 'Inception Date',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'is_offshore',
    name: 'Is Offshore',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'is_feeder_fund',
    name: 'Feeder Fund',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'is_hfr_funded',
    name: 'Is HFR Fund',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'is_minority_fund',
    name: 'Is Minority Managed',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'contact_status',
    name: 'Contact Status',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'liquidity',
    name: 'Liquidity',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'minority_manage_type',
    name: 'Minority Manage Type',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'minority_manage_level',
    name: 'Minority Manage Level',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.INFORMATION,
  },
  {
    key: 'domicile',
    name: 'Domicile',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'structure',
    name: 'Structure',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'registration_code',
    name: 'Registration',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'ucitsiii',
    name: 'UCITSIII',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'liquid_alt_type',
    name: 'Liquid Alt Type',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'sma_weeklyliquid_10mm',
    name: 'SMA Weekly $10MM',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'sma_monthlyliquid_20mm',
    name: 'SMA Monthly $20MM',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'sma_monthlyliquid_50mm',
    name: 'SMA Monthly $50MM',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'aifmd_compliant',
    name: 'AIFMD',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'multi_manager_platform',
    name: 'Multi-Manager Platform',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'min_invest',
    name: 'Min. Investment',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'add_invest',
    name: 'Add Investment',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'woman_managed',
    name: 'Woman Managed',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'use_of_leverage',
    name: 'Use of Leverage',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'liquid_alt_region',
    name: 'Liquid Alt Regions',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },

  {
    key: 'min_invest_indv',
    name: 'Ind. Account Min.',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'accepts_mgd_accts',
    name: 'Accepts Mng. Accts?',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'run_sep_mgd_accts',
    name: 'Run Sep Mng Accts?',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'use_of_leverage_type',
    name: 'Use of Leverage Type',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'liquid_alt_product',
    name: 'Liquid Alt Product',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },
  {
    key: 'long_only_product',
    name: 'Long Only Product',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.STRUCTURE,
  },

  {
    key: 'mgmt_fee',
    name: 'Mgmt. Fee %',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'administrative_fee',
    name: 'Admin. Fee %',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'other_fees',
    name: 'Other Fee %',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'incentive_fee',
    name: 'Incentive Fee %',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'sales_fee',
    name: 'Sales Fee %',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'redemption_fee',
    name: 'Rdm. Fee %',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'redemption_fee_months',
    name: 'If Less Than',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'is_high_watermark',
    name: 'High Watermark',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'hurdle_rate',
    name: 'Hurdle Rate',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'max_12b1_fee',
    name: 'Max 12B-1 Fee %',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'max_front_fee',
    name: 'Max Front-End Load Fee %',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'max_deferred_fee',
    name: 'Max Deferred Sale Load Fee %',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INFO,
    collapse: CollapseFundEnum.FEES,
  },
  {
    key: 'equity_subscription',
    name: 'Equity Subscriptions',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'subscription_advance_notice',
    name: 'Subscription Advanced Notice (days)',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'subscription_cutoff_time',
    name: 'Subscription Cutoff by',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'subscription_settlement_days',
    name: 'Subscription Settment (days)',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'equity_redemption',
    name: 'Equity Redemptions',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'redemption_advance_notice',
    name: 'Redemption Advance Notice (days)',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'redemption_return_percent',
    name: '% Redemption Returned within (days)',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'balance_redemption_return_days',
    name: 'Redemption Balance Returned within (days)',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'investor_type',
    name: 'Accred. Investor Type',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'performance_reporting',
    name: 'Perf. Rept. to Investors',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'prime_broker',
    name: 'Prime Broker',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'banking_agent',
    name: 'Banking Agent',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'legal_adviser',
    name: 'Legal Adviser',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'auditor_name',
    name: 'Auditor',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'placement_agent',
    name: 'Placement Agent',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'administrator',
    name: 'Administrator',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'consultant',
    name: 'Consultant',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'custodian',
    name: 'Custodian',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'constituent_count',
    name: '# of Constituents',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'sub_strategy_desc',
    name: 'Sub-Strategy Desc',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'last_audit_date',
    name: 'Annual Performance Audit at',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'is_lockup',
    name: 'Is Lockup',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'lockup_period',
    name: 'Lockup Period',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'lockup_type',
    name: 'Lockup Type',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'gate_fund',
    name: 'Gate: Fund-Level',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'gate_investor',
    name: 'Gate: Investor-Level',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'accepts_new_investment',
    name: 'Accepting New Investment',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'strategy_description',
    name: 'Strategy Description',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_INVESTMENT_INFO,
    collapse: CollapseFundEnum.STRATEGY_DESCRIPTION,
  },
  {
    key: 'offshore_id',
    name: 'Offshore Fund',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.OFFSHORE_FUND_INFO,
    collapse: CollapseFundEnum.OFFSHORE_FUND_INFORMATION,
  },
  {
    key: 'contact_name',
    name: 'Name',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_company',
    name: 'Company',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_address1',
    name: 'Address 1',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_address2',
    name: 'Address 2',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_city',
    name: 'City',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_postal_code',
    name: 'Postal Code',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_phone',
    name: 'Phone',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_email',
    name: 'Email',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_web',
    name: 'Web',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_state',
    name: 'State',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_country',
    name: 'Country',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_fax',
    name: 'Fax',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.FUND_CONTACT,
  },
  {
    key: 'region_inv_focus',
    name: 'Regional Investment Focus',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.REGION,
  },
  {
    key: 'region_inv_focus_country',
    name: 'Sub-Region',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.REGION,
  },
  {
    key: 'is_emerging_markets_fund',
    name: 'Is Emerging Markets',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.REGION,
  },
  {
    key: 'electronic_trading_platform',
    name: 'Electronic Trading Platform',
    leftNav: LeftNavFundEnum.INFORMATION,
    rightTab: RightTabFundEnum.ELECTRONIC_TRADING_PLATFORM,
  },
  { key: 'is_fund_esg', name: 'Does Fund Consider ESG Factors?', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'esg_strategy', name: 'ESG Strategy', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'esg_sub_strategy', name: 'ESG Sub-Strategy', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'app_positivescreen', name: 'Positive/best-in-class Screening', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'app_negativescreen', name: 'Negative/Exclusionary Screening', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'app_esgintegration', name: 'ESG Integration', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'app_impactinvest', name: 'Impact Investing', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'app_sustaininvest', name: 'Sustainability-themed Investing', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'env_greenbuilding', name: 'Green Building/ Smart Growth', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'env_climatechange', name: 'Climate Change/ Carbon', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'env_cleantech', name: 'Clean Technology', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'env_pollutiontaxes', name: 'Pollution / Toxics', leftNav: LeftNavFundEnum.FUND_ESG },
  {
    key: 'env_sustainable',
    name: 'Sustainable Natural Resources / Agriculture',
    leftNav: LeftNavFundEnum.FUND_ESG,
  },
  { key: 'env_waterconservation', name: 'Water Use & Conservation', leftNav: LeftNavFundEnum.FUND_ESG },
  {
    key: 'env_description',
    name: 'Additional Description Of How ENVIRONMENTAL Is Considered When Investing',
    leftNav: LeftNavFundEnum.FUND_ESG,
  },
  { key: 'soc_workplacesafety', name: 'Workplace Safety', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'soc_laborrelations', name: 'Labor Relations', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'soc_workplacebenefits', name: 'Workplace Benefits', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'soc_diversityantibias', name: 'Diversity & Anti-Bias Issues', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'soc_communitydev', name: 'Community Development', leftNav: LeftNavFundEnum.FUND_ESG },
  {
    key: 'soc_avoidtobacco',
    name: 'Avoidance Of Tobacco Or Other Harmful Products',
    leftNav: LeftNavFundEnum.FUND_ESG,
  },
  { key: 'soc_humanrights', name: 'Human Rights', leftNav: LeftNavFundEnum.FUND_ESG },
  {
    key: 'soc_description',
    name: 'Additional Description Of How SOCIAL Is Considered When Investing',
    leftNav: LeftNavFundEnum.FUND_ESG,
  },
  { key: 'gov_corppolcontrib', name: 'Corporate Political Contributions', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'gov_executivecomp', name: 'Executive Compensation', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'gov_boarddiversity', name: 'Board Diversity', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'gov_anticorruption', name: 'Anti-Corruption Pilicies', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'gov_boardindependence', name: 'Board Independence', leftNav: LeftNavFundEnum.FUND_ESG },
  {
    key: 'gov_description',
    name: 'Additional Description Of How GOVERNANCE Is Considered When Investing',
    leftNav: LeftNavFundEnum.FUND_ESG,
  },
  {
    key: 'team_engagepcm',
    name: `Does This Fund's Investment Team Engage With The Management Teams Of Companies In This Fund's Underlying Poritfolio, Re:ESG Or Diversity Issues?`,
    leftNav: LeftNavFundEnum.FUND_ESG,
  },
  { key: 'data_msci', name: 'MSCI', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'data_sustainalytics', name: 'Sustainalytics', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'data_bloomberg', name: 'Bloomberg', leftNav: LeftNavFundEnum.FUND_ESG },
  { key: 'data_factset', name: 'FactSet', leftNav: LeftNavFundEnum.FUND_ESG },
  {
    key: 'data_internal',
    name: 'Proprietary Or Internally Developed Metrics',
    leftNav: LeftNavFundEnum.FUND_ESG,
  },
  { key: 'data_other', name: 'Other', leftNav: LeftNavFundEnum.FUND_ESG },

  {
    key: 'dead_fund',
    name: 'HFR Dead Funds Database',
    leftNav: LeftNavFundEnum.EXPORT_SETUP,
  },
  {
    key: 'hfr_database',
    name: 'HFR Database',
    leftNav: LeftNavFundEnum.EXPORT_SETUP,
  },
  {
    key: 'hfr_risk_premia',
    name: 'HFR Risk Premia AM Database',
    leftNav: LeftNavFundEnum.EXPORT_SETUP,
  },
  {
    key: 'rationale',
    name: 'Investment Rationale',
    leftNav: LeftNavFundEnum.DUE_DILIGENCE,
    rightTab: RightTabFundEnum.INVEST_OPINIONS,
  },
  {
    key: 'firm',
    name: 'Firm',
    leftNav: LeftNavFundEnum.DUE_DILIGENCE,
    rightTab: RightTabFundEnum.INVEST_OPINIONS,
  },
  {
    key: 'strategy',
    name: 'Strategy',
    leftNav: LeftNavFundEnum.DUE_DILIGENCE,
    rightTab: RightTabFundEnum.INVEST_OPINIONS,
  },
  {
    key: 'asi_strategy',
    name: 'ASI Strategy',
    leftNav: LeftNavFundEnum.INDICES,
  },
  {
    key: 'asi_sub_strategy',
    name: 'ASI Sub-Strategy',
    leftNav: LeftNavFundEnum.INDICES,
  },
  {
    key: 'indexRegion',
    name: 'Index Region',
    leftNav: LeftNavFundEnum.INDICES,
  },
  {
    key: 'countryIndexInfo',
    name: 'Index Country',
    leftNav: LeftNavFundEnum.INDICES,
  },
  {
    key: 'strategy_code',
    name: 'Legacy Strategy',
    leftNav: LeftNavFundEnum.INDICES,
  },
  {
    key: 'sub_strategy_code',
    name: 'Legacy Sub-Strategy',
    leftNav: LeftNavFundEnum.INDICES,
  },
  {
    key: 'performance_added_to_family_as_of',
    name: 'Performance Added to Family as of',
    leftNav: LeftNavFundEnum.INDICES,
  },
  {
    key: 'removed_from_family_after',
    name: 'Removed from Family after',
    leftNav: LeftNavFundEnum.INDICES,
  },
  {
    key: 'family_pending_removal',
    name: 'Family Pending Removal',
    leftNav: LeftNavFundEnum.INDICES,
  },
  ...getFundIndexEligibilityDetailsOptions('accepting_new_investments', 'Accepting New Investments'),
  ...getFundIndexEligibilityDetailsOptions('subscriptions_monthly', 'Subscriptions'),
  ...getFundIndexEligibilityDetailsOptions('subscriptions_advanced', 'Subscriptions, Advanced Notice'),
  ...getFundIndexEligibilityDetailsOptions('redemptions_quarterly', 'Redemptions'),
  ...getFundIndexEligibilityDetailsOptions('redemptions_advanced', 'Redemptions, Advanced Notice'),
  ...getFundIndexEligibilityDetailsOptions('lockup', 'Lockup'),
  ...getFundIndexEligibilityDetailsOptions('gate_investor', 'Gate Investor'),
  ...getFundIndexEligibilityDetailsOptions('gate_fund', 'Gate Fund'),
  ...getFundIndexEligibilityReviewOptions('eligibility_status', 'Eligibility Status'),
  ...getFundIndexEligibilityReviewOptions('exclusion_reason', 'Exclusion Reason'),
  {
    key: 'capacity',
    name: 'Capacity',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'need_to_fund',
    name: 'Need To Fund',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'investment_info_management_fee',
    name: 'Management Fee',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  // {
  //   key: 'incentive_fee',
  //   name: 'Incentive Fee',
  //   leftNav: LeftNavFundEnum.HFR_AM_INFO,
  //   collapse: CollapseFundEnum.INVESTMENT_INFO,
  // },
  {
    key: 'weekly_redemption_percent',
    name: 'Weekly Redemption',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'weekly_hfrx_redemption_percent',
    name: 'Weekly HFRX Redemption',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'monthly_redemption_percent',
    name: 'Monthly Redemption',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'subscription_days',
    name: 'Subscriptions',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'most_recent_review',
    name: 'Most Recent Review',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'remaining_capacity',
    name: 'Remaining Capacity',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'pof_info_management_fee',
    name: 'Management Fee',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'index_pricing',
    name: 'Index Pricing',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  // {
  //   key: 'isin',
  //   name: 'ISIN',
  //   leftNav: LeftNavFundEnum.HFR_AM_INFO,
  //   collapse: CollapseFundEnum.INVESTMENT_INFO,
  // },
  {
    key: 'hfr_am_info_liquidity',
    name: 'Liquidity',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    key: 'hfr_am_info_notes',
    name: 'Notes',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.HFR_AM_INFO_NOTE,
  },
  {
    key: 'market_commentary',
    name: '',
    leftNav: LeftNavFundEnum.HFR_AM_INFO,
    collapse: CollapseFundEnum.HFR_AM_INFO_ARCHIVE_NOTES,
  },
  {
    key: 'has_securities',
    name: '',
    leftNav: LeftNavFundEnum.EXECUTIVE_ORDERS,
  },
  {
    key: 'manager_confirms',
    name: '',
    leftNav: LeftNavFundEnum.EXECUTIVE_ORDERS,
  },
  {
    key: 'separate_share_classes',
    name: '',
    leftNav: LeftNavFundEnum.EXECUTIVE_ORDERS,
  },
  {
    key: 'pre_trade_compliance_restrictions',
    name: '',
    leftNav: LeftNavFundEnum.EXECUTIVE_ORDERS,
  },
  {
    key: 'other_measures',
    name: '',
    leftNav: LeftNavFundEnum.EXECUTIVE_ORDERS,
  },
  {
    key: 'subscription_advance_notice',
    name: '',
    leftNav: LeftNavFundEnum.EXECUTIVE_ORDERS,
  },
]
