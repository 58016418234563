export enum LeftNavExFirmEnum {
  ASSETS_UNDER_MANAGEMENT = 'Assets Under Management (AUM)',
  REGISTRATION = 'Registration',
  CONTACT_INFORMATION = 'Contact Information',
  OWNERSHIP = 'Ownership',
  CO_INVESTMENT = 'Co-Investments',
  FIRM_ESG_POLICIES = 'Firm ESG Policies',
  FIRM_DIVERSITY_INCLUSION = 'Firm Diversity Inclusion',
  FIRM_CMIC = 'Firm CMIC',
  FIRM_MULTI_MANAGER_POD_PLATFORM = `Firm Multi-Manager "Pod" Platform`,
  FUND_FAMILY = 'Fund Family',
}
export interface ListSearchExFirmType {
  key: string
  name: string
  leftNav: LeftNavExFirmEnum
}
export const renderSearchListExFirm: ListSearchExFirmType[] = [
  {
    key: 'denominationAssetUnderManagement',
    name: 'Denomination',
    leftNav: LeftNavExFirmEnum.ASSETS_UNDER_MANAGEMENT,
  },
  {
    key: 'firmRegistration',
    name: 'Firm Registration',
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'sec_registered',
    name: 'Is Firm registered as an Investment Advisor with the SEC?',
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'sec_number',
    name: 'Firm SEC Number',
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'iard_crd_number',
    name: 'Firm IARD / CRD Number',
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'organization_type',
    name: 'Organization Type',
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'country',
    name: 'Under the laws of which Country is Firm organized?',
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'sec_state',
    name: "If 'USA', under the laws of which State is Firm organized?",
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'sec_exemption_reason_type',
    name: 'If Exempt Reporting Advisor ("ERA"), exemption reason',
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'non_sec_country',
    name: 'Country',
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'regulatoryAuthority',
    name: 'Non-SEC Regulatory Authority',
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'registrationType',
    name: 'Non-SEC Registration Type',
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'registrationNumber',
    name: 'Non-SEC Registration Number',
    leftNav: LeftNavExFirmEnum.REGISTRATION,
  },
  {
    key: 'ContactName1Contact',
    name: 'Contact Name 1',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'ContactName2Contact',
    name: 'Contact Name 2',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'AddressContact',
    name: 'Address',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'AddressContact2',
    name: 'Address 2',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'CityContact',
    name: 'City',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'StateContact',
    name: 'State',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'ZipContact',
    name: 'Zip',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'CountryContact',
    name: 'Country',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'PhoneContact',
    name: 'Phone',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'FaxContact',
    name: 'Fax',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'EmailContact',
    name: 'Email',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'WebsiteContact',
    name: 'Website',
    leftNav: LeftNavExFirmEnum.CONTACT_INFORMATION,
  },
  {
    key: 'YearFounded',
    name: 'Year Founded',
    leftNav: LeftNavExFirmEnum.OWNERSHIP,
  },
  {
    key: '% of firm owned by employees',
    name: '% of firm owned by employees',
    leftNav: LeftNavExFirmEnum.OWNERSHIP,
  },
  {
    key: '# of employees with ownership stake',
    name: '# of employees with ownership stake',
    leftNav: LeftNavExFirmEnum.OWNERSHIP,
  },
  {
    key: 'Is this a minority-owned firm?',
    name: 'Is this a minority-owned firm?',
    leftNav: LeftNavExFirmEnum.OWNERSHIP,
  },
  {
    key: 'Minority ownership type',
    name: 'Minority ownership type',
    leftNav: LeftNavExFirmEnum.OWNERSHIP,
  },
  {
    key: 'Minority ownership level',
    name: 'Minority ownership level',
    leftNav: LeftNavExFirmEnum.OWNERSHIP,
  },
  {
    key: 'Does your firm offer co-investment opportunities?',
    name: 'Does your firm offer co-investment opportunities?',
    leftNav: LeftNavExFirmEnum.CO_INVESTMENT,
  },
  {
    key: 'is_firm_esg',
    name: 'Does Your Firm Have A Formal Firmwide ESG Policy?',
    leftNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
  },
  {
    key: 'applied_firmculture',
    name: 'Firm Culture',
    leftNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
  },
  {
    key: 'applied_investmentprocess',
    name: 'Investment Process',
    leftNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
  },
  {
    key: 'applied_portfolioconstruction',
    name: 'Portfolio Construction',
    leftNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
  },
  {
    key: 'applied_esgproducts',
    name: 'ESG Products',
    leftNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
  },
  {
    key: 'applied_esgintegration',
    name: 'ESG Integration',
    leftNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
  },
  {
    key: 'applied_esgactivist',
    name: 'Activist / Engaged Ownership',
    leftNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
  },
  {
    key: 'applied_diversityinclusion',
    name: 'Diversity And Inclusion',
    leftNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
  },
  {
    key: 'receive_guidance',
    name:
      'Does Your Firm Receive ESG Investment Guidance From Or Follow The Principles Of A Third-Party Company/Association/Network?',
    leftNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
  },
  {
    key: 'thirdparty_guidance',
    name: 'Identify The Third-Party Company',
    leftNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
  },
  {
    key: 'future',
    name: 'Do You Have Plans To Incorporate ESG In The Future?',
    leftNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
  },
]
