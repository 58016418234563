import React from 'react'
import { Input } from 'antd'
import { InputProps } from 'antd/lib/input'

type CustomNumberInputProps = InputProps & {
  precision?: number
}

export function CustomNumberInput(props: CustomNumberInputProps) {
  const { value, onChange, max = 100.01, min = 0, precision = 0, onBlur, ...otherProps } = props
  if (!Number.isInteger(precision) || precision < 0) {
    throw new Error('precision is not right')
  }

  return (
    <Input
      {...otherProps}
      value={value}
      onChange={(e) => {
        const inputVal = e.target.value
        const inputNumber = inputVal.replace(/[^0-9\.]/g, '')
        if (!isNaN(Number(inputNumber)) && inRange(Number(inputVal), Number(min), Number(max))) {
          onChange && onChange(e)
        }
      }}
      onBlur={(e) => {
        const value = e.target.value
        if (typeof value === 'undefined' || value === null || value === '') {
          return ''
        }
        const inputNumber = Number(value) || 0
        /** formate */
        e.target.value = Number(inputNumber).toFixed(precision)
        onChange && onChange(e)
        onBlur && onBlur(e)
      }}
    />
  )
}

function inRange(x: number, min: number, max: number) {
  if (x >= min && x <= max) {
    return true
  }
  return false
}
