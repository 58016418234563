export type FundDetails = {
  accepts_mgd_accts: string | null
  accepts_new_investment: boolean | null
  add_invest: string | null
  added_to_db: string | null
  administrative_fee: string | null
  administrator: string | null
  aifmd_compliant: string | null
  allocation: string | null
  app_esgintegration: boolean | null
  app_impactinvest: boolean | null
  app_negativescreen: boolean | null
  app_positivescreen: boolean | null
  app_sustaininvest: boolean | null
  auditor_name: string | null
  balance_redemption_return_days: number | null
  banking_agent: string | null
  benchmark1: string | null
  benchmark2: string | null
  benchmark3: string | null
  bloomberg_ticker: string | null
  capacity: string | null
  constituent_count: number | null
  consultant: string | null
  contact_status: string | null
  custodian: string | null
  data_bloomberg: string | null
  data_factset: string | null
  data_internal: string | null
  data_msci: string | null
  data_other: string | null
  data_source: string | null
  data_sustainalytics: string | null
  dead_fund: boolean | null
  denomination: string | null
  domicile: string | null
  env_cleantech: boolean | null
  env_climatechange: boolean | null
  env_description: boolean | null
  env_greenbuilding: boolean | null
  env_pollutiontaxes: boolean | null
  env_sustainable: boolean | null
  env_waterconservation: boolean | null
  equity_redemption: string | null
  equity_subscription: string | null
  firm_assets: string | null
  firm_assets_currency: string | null
  firm_assets_date: string | null
  firm_id: string
  firm_name: string
  fund_assets: number | null
  fund_assets_date: string | null
  firm_denomination: string | null
  fund_contact: FundContact
  fund_id: string
  fund_name: string
  fund_status_code: string | null
  firm_contact_country: string | null
  gate_fund: string | null
  gate_investor: string | null
  gov_anticorruption: boolean | null
  gov_boarddiversity: boolean | null
  gov_boardindependence: boolean | null
  gov_corppolcontrib: boolean | null
  gov_description: boolean | null
  gov_executivecomp: boolean | null
  has_index_factsheet: boolean
  hfr_am_info_liquidity: string | null
  hfr_am_info_notes: string | null
  hfr_analyst: string | null
  hfr_database: boolean | null
  hfr_indices: FundIndexManagerType[]
  hfr_la_fund: boolean | null
  hfr_risk_premia: boolean | null
  hfrb_fund: boolean | null
  hfri500_eligibility: string | null
  hfri500_exclusion_reason: string | null
  hfri500_reviewed: string | null
  hfri_500_fund: boolean | null
  hfri_fund: boolean | null
  hfri_i_fund: boolean | null
  hfri_institutional_fund: boolean | null
  hfri_pending_fund: boolean | null
  hfri_pending_removal: boolean | null
  hfri_woman_access_fund: boolean | null
  hfrrparity_fund: boolean | null
  hfrrparity_i_fund: boolean | null
  hfru_fund: boolean | null
  hfrx_fund: boolean | null
  hurdle_rate: string | null
  incentive_fee: string | null
  inception_date: string | null
  index_pricing: string | null
  investor_type: string | null
  is_annual_performance_audit: boolean | null
  is_aum_distributed: boolean | null
  is_nav_distributed: boolean | null
  is_emerging_markets_fund: boolean | null
  is_feeder_fund: boolean | null
  is_fund_esg: string | null
  is_hfr_funded: boolean | null
  is_high_watermark: boolean | null
  is_hurdle_rate: boolean | null
  is_liquidated: boolean | null
  is_lockup: string | null
  is_minority_fund: boolean | null
  is_offshore: boolean | null
  isin: string | null
  last_audit_date: string | null
  legal_adviser: string | null
  liquid_alt_product: boolean | null
  liquid_alt_region: string | null
  liquid_alt_type: string | null
  liquidated_at: string | null
  liquidity: string | null
  lockup_period: string | null
  lockup_type: string | null
  max_12b1_fee: string | null
  max_deferred_fee: string | null
  max_front_fee: string | null
  mgmt_fee: string | null
  min_invest: string | null
  min_invest_indv: string | null
  minority_manage_level: string | null
  minority_manage_type: string | null
  monthly_redemption_day_type: number | null
  monthly_redemption_days: number | null
  monthly_redemption_percent: string | null
  most_recent_review: string | null
  multi_manager_platform: string | null
  need_to_fund: string | null
  notes: string | null
  om_date: string | null
  other_fees: string | null
  performance_reporting: string | null // not use
  placement_agent: string | null
  prime_broker: string | null
  principals: Principal[]
  product_class: string | null
  redemption_advance_notice: number | null
  redemption_fee: string | null
  redemption_fee_months: string | null
  redemption_return_days: number | null
  redemption_return_percent: number | null
  region_inv_focus: string | null
  region_inv_focus_country: string | null
  registration_code: string | null
  remaining_capacity: string | null
  remaining_hfrx_capacity: string | null
  reporting_frequency: string | null
  reporting_style: string | null
  run_sep_mgd_accts: string | null
  sales_fee: string | null
  sec_pfid_number: string | null
  sma_monthlyliquid_20mm: string | null
  sma_monthlyliquid_50mm: string | null
  sma_weeklyliquid_10mm: string | null
  soc_avoidtobacco: boolean | null
  soc_communitydev: boolean | null
  soc_description: boolean | null
  soc_diversityantibias: boolean | null
  soc_humanrights: boolean | null
  soc_laborrelations: boolean | null
  soc_workplacebenefits: boolean | null
  soc_workplacesafety: boolean | null
  strategy_code: number | null
  strategy_code_new: number | null
  strategy_description: string | null
  structure: string | null
  style: string | null
  sub_strategy_code: string | null
  sub_strategy_code_new: number | null
  sub_strategy_desc: string | null
  subscription_cutoff_time: string | null
  subscription_cutoff_timezone: string | null
  subscription_day_type: number | null
  subscription_days: number | null
  subscription_settlement_days: string | null
  subscription_notice: number | null
  subscription_settlement: number | null
  team_engagepcm: string | null
  ucitsiii: string | null
  use_of_leverage: string | null
  use_of_leverage_type: string | null
  weekly_hfrx_redemption_day_type: number | null
  weekly_hfrx_redemption_days: number | null
  weekly_hfrx_redemption_percent: string | null
  weekly_redemption_days: number | null
  weekly_redemption_percent: string | null
  weekly_redemption_day_type: number | null

  woman_managed: string | null
  added_to_hfri: string | null // YYYY-MM
  removed_from_hfri: string | null // YYYY-MM

  subscription_advance_notice: string | null
  contact_name: string | null
  contact_company: string | null
  contact_country: string | null
  contact_address1: string | null
  contact_address2: string | null
  contact_city: string | null
  contact_state: string | null
  contact_postal_code: string | null
  contact_phone: string | null
  contact_fax: string | null
  contact_email: string | null
  contact_web: string | null

  offshore_id: string | null
  //
  fund_family_code: string | null
  fund_family_id: string | null
} & FundCCMCResponse

export interface ExFundFieldChange {
  min_invest_input: number
  add_invest_input: number
  min_invest_indv_input: number
  auditor_name_input: string
  legal_adviser_input: string
  prime_broker_input: string
  administrator_input: string
  domicile_input: string
}

export interface FundDetailsPreviewType {
  added_to_db: string
  benchmarks: FundDetailBenchmark
  domicile: string
  firm: FundDetailFirm
  firm_contact: FundDetailFirmContact
  firm_sec: FundDetailFirmSec
  fund_assets_currency: string
  fund_assets: string | number
  fund_assets_rep_date: string
  fund_fees: FundDetailFirmFee
  fund_id: string
  fund_info: FundDetailFundInfo
  fund_name: string
  is_emrgngmrkts_fund: boolean
  is_hfrx_fund: boolean
  is_hfru_fund: boolean
  is_hfr_risk_parity_fund: boolean
  is_hfr_blockchain_fund: boolean
  is_index_fund: boolean
  leverage: string
  liquid_alt_region: string
  liquid_alt_type: string
  regional_focus: string
  strategy: FundDetailStrategy
  structure: string
  sub_strategy: FundDetailSubStrategy
  redemptions: string
  subscriptions: string
  ucits_compliant?: boolean
  min_investment_curr: string
  reporting_style: string
  returns_currency: string

  // Additional data loaded separately in fund details
  // ror?: any;
  // aum?: any;
  // benchmarkRor?: any;
}

export interface FundIndexManagerType {
  description: string
  id: number
  name: string
  value: boolean
}

export interface FundContact {
  address1: string
  address2: string
  city: string
  company: string
  country: string
  description: string
  email: string
  fax: string
  name: string
  phone: string
  postal_code: string
  state_code: string
  web: string
}

export interface Principal {
  rank: number
  name: string
  title: string
}

export interface FundAllInfo {
  added_to_db: string
  domicile: string
  firm: FundDetailFirm
  firm_contact: FundDetailFirmContact
  firm_sec: FundDetailFirmSec
  fund_assets: string | number
  fund_assets_currency: string
  fund_assets_rep_date: string
  fund_export: Record<string, unknown>
  fund_fees: FundDetailFirmFee
  fund_id: string
  fund_info: FundDetailFundInfo
  fund_name: string
  is_emrgngmrkts_fund: boolean
  is_hfrx_fund: boolean
  is_hfru_fund: boolean
  is_hfr_risk_parity_fund: boolean
  is_hfr_blockchain_fund: boolean
  is_index_fund: boolean
  leverage: string
  liquid_alt_region: string
  liquid_alt_type: string
  regional_focus: string
  statistics: FundAllInfoStatistic
  strategy: FundDetailStrategy
  structure: string
  sub_strategy: FundDetailSubStrategy
  redemptions: string
  subscriptions: string
  ucits_compliant?: boolean
  min_investment_curr: string
  reporting_style: string
  returns_currency: string
  valuation: {
    firm_usd_value: string
    fund_usd_value: string
  }
}

export interface FundAllInfoStatistic {
  alpha: number
  ann_ror: number
  ann_stdev: number
  beta: number
  correlation: number
  down_alpha: number
  down_beta: number
  down_r_square?: number | string
  end_date: string
  five_year_ann_ror: number
  five_year_ann_sharpe_ratio: number
  five_year_ann_stdev: number
  geo_avg_month_ror: number
  high_month_ror: number
  low_month_ror: number
  one_month_ror: number
  one_year_ann_ror: number
  one_year_ann_sharpe_ratio: number
  one_year_ann_stdev: number
  r_square: number
  seven_year_ann_ror: number | string
  seven_year_ann_sharpe_ratio: number | string
  seven_year_ann_stdev: number | string
  sharpe_ratio: number
  six_month_ror: number
  start_date: string
  stdev: number
  ten_year_ann_ror: number | string
  ten_year_ann_sharpe_ratio: number | string
  ten_year_ann_stdev: number | string
  three_month_ror: number
  three_year_ann_ror: number
  three_year_ann_sharpe_ratio: number
  three_year_ann_stdev: number
  track_record: number
  two_year_ann_ror: number
  two_year_ann_sharpe_ratio: number
  two_year_ann_stdev: number
  up_alpha: number
  up_beta: number
  up_r_square: number | string
  winning_month_percentage: number
}
export interface FirmFundDetail {
  fund_id: string
  fund_name: string
  fund_assets: string
  strategy_name: string
  sub_strategy_name: string
  inception_date: string
  fund_assets_rep_date: string
  fund_assets_currency: string
  has_permission: boolean
}

export interface FirmDetails extends FundDetailFirm {
  iard_crd_number: string
  sec_number: string
  sec_registered: boolean
  principals: string
}

export interface FirmDetailResponse {
  firm: FirmDetails
  firm_contact: FundDetailFirmContact
  firm_funds: FirmFundDetail[]
}

export interface FundDetailBenchmark {
  benchmark_1: string
  benchmark_2: string
  benchmark_3: string
}
export interface FundDetailFirm {
  firm_assets_currency: string
  firm_assets: string | number
  firm_assets_date: string
  firm_id: string
  firm_name: string
  is_diversity_firm: boolean
  email: string
}
export interface FundDetailFirmContact {
  address?: string
  city?: string
  country?: string
  facsimile?: string
  first_contact?: string
  pdf_address?: string
  phone?: string
  postal_code?: string
  principals?: string
  second_contact?: string
  state?: string
  email?: string
}

export interface FundDetailFirmSec {
  iard_crd_number?: string
  sec_number?: string
  sec_registered: boolean
}

export interface FundDetailFirmFee {
  hurdle_rate?: string
  high_watermark?: boolean
  incentive_fee: string
  max_12b1_fee?: string
  max_deferred_fee?: string
  max_front_fee?: string
  mgmt_fee?: string
  other_fees?: string
  sales_fee?: string
}

export interface FundDetailFundInfo {
  additional_investment: string
  advance_notice: number | string
  administrator: string
  annual_perform_audit: string
  audit_date: string
  auditor: string
  banking_agent_onshore: string
  custodian: string
  fund_registration_code: string
  inception_date: string
  individual_account_min: string
  is_offshore: boolean
  legal_adviser_onshore: string
  liquid_alt_product?: boolean
  lockup?: string
  minimum_investment: string
  open_for_investment: string
  open_to_investor_type: string
  prime_broker: string
  product_class?: string
  region_inv_focus: number
  region_inv_focus_country?: string
  region_inv_focus_desc?: string
  reporting: string
  strategy_description?: string
}

export interface FundDetailRegionalFocus {
  id: string
  name: string
}

export interface StrategyResponse {
  strategies: FundDetailStrategy[]
  sub_strategies: FundDetailSubStrategy[]
}

export interface FundDetailStrategy {
  benchmark: string
  strategy_code: number
  strategy_name: string
}

export interface FundDetailSubStrategy {
  benchmark: string
  strategy_code: number
  sub_strategy_code: number
  sub_strategy_name: string
}

export interface FirmFundDescriptionRecord {
  key: string
  value: string | string[]
  show: boolean
}

export interface FundDropdownType {
  accepts_mgd_accts: FundDropdownCodeType[]
  add_invest: string[]
  administrator: string[]
  aifmd_compliant: FundDropdownCodeType[]
  allocation: FundDropdownCodeType[]
  app_esgintegration: FundDropdownCodeType[]
  app_impactinvest: FundDropdownCodeType[]
  app_negativescreen: FundDropdownCodeType[]
  app_positivescreen: FundDropdownCodeType[]
  app_sustaininvest: FundDropdownCodeType[]
  applied_diversityinclusion: FundDropdownCodeType[]
  applied_esgactivist: FundDropdownCodeType[]
  applied_esgintegration: FundDropdownCodeType[]
  applied_esgproducts: FundDropdownCodeType[]
  applied_firmculture: FundDropdownCodeType[]
  applied_investmentprocess: FundDropdownCodeType[]
  applied_portfolioconstruction: FundDropdownCodeType[]
  auditor_name: string[]
  coinvestment_number: FundDropdownCodeType[]
  contact_country: FundDropdownCodeType[]
  contact_state: FundDropdownCodeType[]
  country: FundDropdownCodeType[]
  contact_status: FundDropdownCodeType[]
  data_bloomberg: FundDropdownCodeType[]
  data_factset: FundDropdownCodeType[]
  data_internal: FundDropdownCodeType[]
  data_msci: FundDropdownCodeType[]
  data_source: FundDropdownCodeType[]
  data_sustainalytics: FundDropdownCodeType[]
  denomination: FundDropdownCodeType[]
  domicile: string[]
  env_cleantech: FundDropdownCodeType[]
  env_climatechange: FundDropdownCodeType[]
  env_description: FundDropdownCodeType[]
  env_greenbuilding: FundDropdownCodeType[]
  env_pollutiontaxes: FundDropdownCodeType[]
  env_sustainable: FundDropdownCodeType[]
  env_waterconservation: FundDropdownCodeType[]
  equity_redemption: FundDropdownCodeType[]
  equity_subscription: FundDropdownCodeType[]
  fund_status_code: FundDropdownCodeType[]
  future: FundDropdownCodeType[]
  gate_fund: FundDropdownCodeType[]
  gate_investor: FundDropdownCodeType[]
  gov_anticorruption: FundDropdownCodeType[]
  gov_boarddiversity: FundDropdownCodeType[]
  gov_boardindependence: FundDropdownCodeType[]
  gov_corppolcontrib: FundDropdownCodeType[]
  gov_executivecomp: FundDropdownCodeType[]
  hfr_analyst: FundDropdownCodeType[]
  hfri500_eligibility: FundDropdownCodeType[]
  hfri500_exclusion_reason: FundDropdownCodeType[]
  investor_type: string[]
  is_firm_esg: FundDropdownCodeType[]
  is_fund_esg: FundDropdownCodeType[]
  is_lockup: FundDropdownCodeType[]
  legal_adviser: string[]
  liquid_alt_region: FundDropdownCodeType[]
  liquid_alt_type: FundDropdownCodeType[]
  liquidity: FundDropdownCodeType[]
  lockup_period: FundDropdownCodeType[]
  lockup_type: FundDropdownCodeType[]
  min_invest: string[]
  min_invest_indv: string[]
  minority_manage_level: FundDropdownCodeType[]
  minority_manage_type: FundDropdownCodeType[]
  minority_own_level: FundDropdownCodeType[]
  minority_own_type: FundDropdownCodeType[]
  non_sec_country: FundDropdownCodeType[]
  non_sec_reg_auth: FundDropdownCodeType[]
  organization_type: FundDropdownCodeType[]
  other_instrument: FundDropdownCodeType[]
  other_vehicle: FundDropdownCodeType[]
  performance_reporting: string[]
  prime_broker: string[]
  private_debt: FundDropdownCodeType[]
  private_equity: FundDropdownCodeType[]
  product_class: FundDropdownCodeType[]
  public_debt: FundDropdownCodeType[]
  public_equity: FundDropdownCodeType[]
  receive_guidance: FundDropdownCodeType[]
  region_inv_focus: FundDropdownCodeType[]
  region_inv_focus_country: FundDropdownCodeType[]
  registration_code: string[]
  reporting_frequency: FundDropdownCodeType[]
  reporting_style: string[]
  run_sep_mgd_accts: FundDropdownCodeType[]
  sec_country: FundDropdownCodeType[]
  sec_exemption_reason_type: FundDropdownCodeType[]
  sec_state: FundDropdownCodeType[]
  sma_monthlyliquid_20mm: FundDropdownCodeType[]
  sma_monthlyliquid_50mm: FundDropdownCodeType[]
  sma_weeklyliquid_10mm: FundDropdownCodeType[]
  soc_avoidtobacco: FundDropdownCodeType[]
  soc_communitydev: FundDropdownCodeType[]
  soc_diversityantibias: FundDropdownCodeType[]
  soc_humanrights: FundDropdownCodeType[]
  soc_laborrelations: FundDropdownCodeType[]
  soc_workplacebenefits: FundDropdownCodeType[]
  soc_workplacesafety: FundDropdownCodeType[]
  structure: string[]
  style: FundDropdownCodeType[]
  subscription_advance_notice: FundDropdownCodeType[]
  subscription_cutoff_time: FundDropdownCodeType[]
  subscription_cutoff_timezone: FundDropdownCodeType[]
  subscription_settlement_days: FundDropdownCodeType[]
  team_engagepcm: FundDropdownCodeType[]
  thirdparty_guidance: FundDropdownCodeType[]
  typical_liquidity: FundDropdownCodeType[]
  typical_size: FundDropdownCodeType[]
  ucitsiii: string[]
  use_of_leverage_type: FundDropdownCodeType[]
  vehicle_commingled_fund: FundDropdownCodeType[]
  vehicle_fund_of_one: FundDropdownCodeType[]
  vehicle_managed_account: FundDropdownCodeType[]
  firm_contact_country: FundDropdownCodeType[]
  woman_managed: FundDropdownCodeType[]
}

export interface FundDropdownCodeType {
  code_code: string
  code_description: string
}

export interface BasicInfoFirm {
  firm_id: string
  firm_name: string
}

export interface FundBenchmark {
  fund_id: string
  fund_name: string
}
export interface LeverageResponseType {
  minimum?: number
  maximum?: number
  average?: number
  date: string
}

export interface InstrumentResponseType {
  name: string
  long_value?: number
  short?: number
  net?: number
  date: string
}

export interface IndustryGroupsType {
  name: string
  long_value?: number
  short?: number
  net?: number
  date: string
}

export interface InvestorType {
  name: string
  date: string
  assets?: number
  number?: number
  quarter?: number
  year?: number
}

export interface RegionWeightType {
  name: string
  long_value?: number
  short?: number
  net?: number
  date: string
}

export interface LeverageUpdateRequestType {
  work_date: string // YYYY-MM-DD
  updates: {
    minimum?: number
    maximum?: number
    average?: number
    work_date?: string
  }
}

export interface LeverageCreateRequestType {
  work_date: string // YYYY-MM-DD
  minimum?: number
  maximum?: number
  average?: number
}

export interface InstrumentUpdateRequestType {
  work_date: string
  name: string
  updates: {
    long_value?: number
    short?: number
    net?: number
    name?: string
    work_date?: string
  }
}

export interface InstrumentCreateRequestType {
  work_date: string // YYYY-MM-DD
  name: string
  long_value?: number
  short?: number
  net?: number
}

export interface InvestorCreateRequestType {
  work_date: string
  name: string
  assets?: number
  number?: number
}

export interface InvestorUpdateRequestType {
  work_date: string
  name: string
  updates: {
    assets?: number
    number?: number
    name?: string
    work_date?: string
  }
}
export interface FundDetailsUniverses {
  is_active_in_universe: boolean
  is_in_universe: boolean
  status_in_universe: string
  universe_description: string
  universe_id: number
  universe_name: string
}
export interface UniversesUpdate {
  updates: { [key: string]: string | boolean | number }[]
}
export interface FundIndicesUpdate {
  updates: { index_id: number; value: boolean }[]
}
export interface DueDiligenceInvestorUpdate {
  rationale: string
  firm: string
  strategy: string
}

export interface LegacyStrategyResponse {
  legacy_strategies: FundDetailStrategy[]
  sub_strategies: FundDetailSubStrategy[]
}

export interface AsiStrategyResponse {
  asi_strategies: { id: number; name: string }[]
  asi_sub_strategies: { id: number; name: string; asi_strategy_id: number }[]
}

export interface FundTableDropDown {
  electronic_trading_platforms: string[]
  industries: string[]
  instruments: string[]
  investor_types: { [key: number]: string }[]
  region_weights: string[]
}
export interface FundActivationParams {
  contact_person: string
  contact_phone: string
  contact_email: string
  additional_comments: string
}

export interface DueDiligenceInvestor {
  firm: string
  rationale: string
  strategy: string
  oper_id: string
  date_time: string
}

export interface DueDiligenceContact {
  contact_name: string
  first_name: string
  last_name: string
  title: string
  department: string
  address1: string
  address2: string
  address3: string
  city: string
  state_code: string
  zip: string
  country: string
  phone: string
  fax: string
  email: string
  web: string
  description: string
}

export interface DueDiligenceScoreCardResponse {
  last_oper_id: string
  last_update: string
  scorecards: DueDiligenceScoreCard[]
}
export interface DueDiligenceScoreCard {
  category: string
  comment: string
  date_time: string
  oper_id: string
  score: number
  weight: number
}

export interface DueDiligenceScoreCardUpdate {
  identifier: string[]
  weight: number
  score: number
  comment: string
}

export interface DueDiligenceMonthly {
  date: string
  internal: string
  monthly: string
  oper_id: string
  slippage: string
}

export interface DueDiligenceMonthlyUpdate {
  date: string
  monthly: string
  slippage: string
  internal: string
  username: string
}
export interface DueDiligenceDocumentsResponse {
  firm_url: string
  search_url: string
}

export interface PrincipalsResponse {
  fund_id: string
  rank: number
  name: string
  title: string
}
export interface PrincipalsUpdateParams {
  identifier: (string | number)[]
  rank: number
  name: string
  title: string
}

export interface PrincipalCreateParams {
  fund_id: string
  rank: number
  name: string
  title: string
}

export interface APGLResponse {
  monthly_data: MonthlyAPGL[]
  ytd_data: { [key: number]: string }
}
export interface MonthlyAPGL {
  year: number
  month: number
  value: string
}

export interface FundPropertiesParams {
  prop_id: number
  prop_value: string
}
export interface FundPropertiesResponse {
  codes: { prop_id: number; prop_name: string }[]
  fund_properties: PropertiesInfo[]
}
export interface PropertiesInfo {
  prop_id: number
  prop_name: string
  prop_value: string
}

export interface FundSearchParams {
  where: FundSearchWhereParams
  peer_group_code?: number[]
  page: number
  limit: number
  order_by: string
  sort_by: string
  columns: string[]
}

export interface FundSearchWhereParams {
  fund_diversity_and_inclusion_types?: string[]
  firm_diversity_and_inclusion_types?: string[]
  fund_id?: string
  fund_name?: string
  fund_curr_code?: string[]
  fund_status_code?: string[]
  hfr_analyst?: string[]
  bloomberg_ticker?: string
  liquidate_date?: { gte?: string; lte?: string }
  isin?: string
  sec_pfid_number?: string
  reporting_style?: string[]
  reporting_frequency?: string[]
  is_aum_distributed?: boolean
  is_nav_distributed?: boolean
  is_feeder_fund?: boolean
  minority_manage_type?: string[]
  minority_manage_level?: string[]
  structure?: string
  woman_managed?: string[]
  domicile?: string[]
  is_liquidated?: boolean
  ucitsiii?: string[]
  accepts_mgd_accts?: string[]
  strategy_new?: string[]
  sub_strategy_new?: string[]
  strategy?: string[]
  sub_strategy?: string[]
  aifmd_compliant?: string[]
  run_sep_mgd_accts?: string[]
  hfri_pending_removal?: boolean
  hfri500_eligibility?: string[]
  hfri500_excl_reason?: string[]
  hfri500_reviewed?: { gte?: string; lte?: string }
  added_to_hfri?: string
  removed_from_hfri?: string
  firm_id?: string
  firm_name?: string
  data_source_code?: string[]
  firm_assets?: { gte?: number; lte?: number }
  firm_assets_date?: { gte?: string; lte?: string }
  product_class?: string[]
  inception_date?: { gte?: string; lte?: string }
  is_offshore?: boolean
  is_minority_fund?: boolean
  contact_status?: string[]
  liquidity?: { gte?: number; lte?: number }
  registration_code?: string[]
  liquid_alt_type?: string[]
  liquid_alt_region?: string[]
  auditor_name?: string[]
  prime_broker?: string[]
  banking_agent?: string
  legal_adviser?: string[]
  placement_agent?: string
  consultant?: string
  custodian?: string
  administrator?: string[]
  redemption_return_days?: { gte?: number; lte?: number }
  redemption_return_percent?: { gte?: number; lte?: number }
  balance_redemption_return_days?: { gte?: number; lte?: number }
  equity_subsc_code?: string[]
  om_date?: { gte?: string; lte?: string }
  is_hfr_funded?: boolean
  perf_reporting?: string[]
  constituent_count?: { gte?: number; lte?: number }
  does_accept_new_invest?: boolean
  lockup_type?: string[]
  lockup_period?: string[]
  gate_fund?: string[]
  gate_investor?: string[]
  is_lockup?: string[]
  subscription_notice?: string[]
  sub_cutoff_time?: string[]
  sub_cutoff_zone?: string[]
  adv_notice_days?: { gte?: number; lte?: number }
  is_annual_perf_audit?: boolean
  min_invest?: { gte?: number; lte?: number }
  add_invest?: { gte?: number; lte?: number }
  region_inv_focus?: string[]
  min_invest_indv?: string[]
  last_audit_date?: { gte?: string; lte?: string }
  investor_type?: string[]
  subscription_settlement?: string[]
  equity_redem_code?: string[]
  sma_weeklyliquid_10mm?: string[]
  sma_monthlyliquid_20mm?: string[]
  sma_monthlyliquid_50mm?: string[]
  use_leverage?: string
  leverage_type?: string[]
  liquid_alt_product?: boolean
  offshore_id?: string
}

export interface ListFundIdResponse {
  fund_ids: string[]
  total: number
}

export interface FundSearchResponse {
  total: number
  funds: FundSearchColumnResponse[]
}

export interface FundSearchColumnResponse {
  accepts_mgd_accts: string
  add_invest: string
  added_to_hfri: string
  administrator: string
  adv_notice_days: number
  aifmd_compliant: string
  auditor_name: string
  balance_redemption_return_days: number
  banking_agent: string
  bloomberg_ticker: string
  constituent_count: number
  consultant: string
  contact_status: string
  custodian: string
  data_source_code: string
  denomination: string
  does_accept_new_invest: number
  domicile: string
  equity_redem_code: string
  equity_subsc_code: string
  firm_assets: string
  firm_assets_date: string
  firm_assets_currency: string
  firm_id: string
  firm_name: string
  full_count: number
  fund_curr_code: string
  fund_id: string
  fund_name: string
  fund_status_code: string
  gate_fund: string
  gate_investor: string
  hfr_analyst: string
  hfri500_eligibility: string
  hfri500_excl_reason: string
  hfri500_reviewed: string
  hfri_pending_removal: boolean
  inception_date: string
  investor_type: string
  is_annual_perf_audit: boolean
  is_aum_distributed: boolean
  is_nav_distributed: boolean
  is_feeder_fund: boolean
  is_hfr_funded: boolean
  is_liquidated: boolean
  is_lockup: string
  is_minority_fund: boolean
  is_offshore: boolean
  isin: string
  last_audit_date: string
  legal_adviser: string
  leverage_type: string
  liquid_alt_product: boolean
  liquid_alt_region: string
  liquid_alt_type: string
  liquidate_date: string
  liquidity: number
  lockup_period: string
  lockup_type: string
  min_invest: string
  min_invest_indv: string
  minority_manage_level: string
  minority_manage_type: string
  offshore_id: string
  om_date: string
  perf_reporting: string
  placement_agent: string
  prime_broker: string
  product_class: string
  redemption_return_days: number
  redemption_return_percent: number
  region_inv_focus: string
  registration_code: string
  removed_from_hfri: string
  reporting_frequency: string
  reporting_style: string
  run_sep_mgd_accts: string
  sec_pfid_number: string
  sma_monthlyliquid_20mm: string
  sma_monthlyliquid_50mm: string
  sma_weeklyliquid_10mm: string
  strategy_code_new: number
  structure: string
  sub_cutoff_time: string
  sub_cutoff_zone: string
  sub_strategy_code_new: number
  subscription_notice: string
  subscription_settlement: string
  ucitsiii: string
  use_leverage: string
  woman_managed: string
  firm_denomination: string
}

export interface FilterInfoColumn {
  fund_id?: string[] | null
  fund_name?: string[] | null
  fund_curr_code?: string[] | null
  fund_status_code?: string[] | null
  hfr_analyst?: string[] | null
  bloomberg_ticker?: string[] | null
  liquidate_date?: string[] | null
  isin?: string[] | null
  sec_pfid_number?: string[] | null
  reporting_style?: string[] | null
  reporting_frequency?: string[] | null
  is_aum_distributed?: string[] | null
  is_nav_distributed?: string[] | null
  is_feeder_fund?: string[] | null
  minority_manage_type?: string[] | null
  minority_manage_level?: string[] | null
  structure?: string[] | null
  woman_managed?: string[] | null
  domicile?: string[] | null
  is_liquidated?: string[] | null
  ucitsiii?: string[] | null
  accepts_mgd_accts?: string[] | null
  strategy_new?: string[] | null
  sub_strategy_new?: string[] | null
  strategy?: string[] | null
  sub_strategy?: string[] | null
  aifmd_compliant?: string[] | null
  run_sep_mgd_accts?: string[] | null
  hfri_pending_removal?: string[] | null
  hfri500_eligibility?: string[] | null
  hfri500_excl_reason?: string[] | null
  hfri500_reviewed?: string[] | null
  added_to_hfri?: string[] | null
  removed_from_hfri?: string[] | null
  firm_id?: string[] | null
  firm_name?: string[] | null
  data_source_code?: string[] | null
  firm_assets?: string[] | null
  firm_assets_date?: string[] | null
  product_class?: string[] | null
  inception_date?: string[] | null
  is_offshore?: string[] | null
  is_minority_fund?: string[] | null
  contact_status?: string[] | null
  liquidity?: string[] | null
  registration_code?: string[] | null
  liquid_alt_type?: string[] | null
  liquid_alt_region?: string[] | null
  auditor_name?: string[] | null
  prime_broker?: string[] | null
  banking_agent?: string[] | null
  legal_adviser?: string[] | null
  placement_agent?: string[] | null
  consultant?: string[] | null
  custodian?: string[] | null
  administrator?: string[] | null
  redemption_return_days?: string[] | null
  redemption_return_percent?: string[] | null
  balance_redemption_return_days?: string[] | null
  equity_subsc_code?: string[] | null
  om_date?: string[] | null
  is_hfr_funded?: string[] | null
  perf_reporting?: string[] | null
  constituent_count?: string[] | null
  does_accept_new_invest?: string[] | null
  lockup_type?: string[] | null
  lockup_period?: string[] | null
  gate_fund?: string[] | null
  gate_investor?: string[] | null
  is_lockup?: string[] | null
  subscription_notice?: string[] | null
  sub_cutoff_time?: string[] | null
  sub_cutoff_zone?: string[] | null
  adv_notice_days?: string[] | null
  is_annual_perf_audit?: string[] | null
  min_invest?: string[] | null
  add_invest?: string[] | null
  region_inv_focus?: string[] | null
  min_invest_indv?: string[] | null
  last_audit_date?: string[] | null
  investor_type?: string[] | null
  subscription_settlement?: string[] | null
  equity_redem_code?: string[] | null
  sma_weeklyliquid_10mm?: string[] | null
  sma_monthlyliquid_20mm?: string[] | null
  sma_monthlyliquid_50mm?: string[] | null
  use_leverage?: string[] | null
  leverage_type?: string[] | null
  liquid_alt_product?: string[] | null
  offshore_id?: string[] | null
  peer_group_code?: number[] | null
}

export interface ListPlatformResponse {
  platforms: string[]
}

export type FundCCMCResponse = {
  fund_id: string
  fund_name: string
  has_securities: boolean
  manager_confirms: boolean
  separate_share_classes: boolean
  pre_trade_compliance_restrictions: boolean
  other_measures: string | null
  subscription_notice: string
}

export type FundESGStrategies = {
  esg_strategies: {
    code: number
    name: string
  }[]
  esg_sub_strategies: {
    esg_strategy: number
    code: number
    name: string
  }[]
  esg_sub_strategy_dropdown: {
    [key: number]: number[]
  }
}

export enum INDEX_ELIGIBILITY_ENUM {
  ELIGIBLE = 'Eligible',
  NOT_ELIGIBLE = 'Not eligible',
  UNDER_REVIEW = 'Under review',
}
export enum WAIVE_ENUM {
  WILLING_TO_WAIVE = 'Willing to waive',
  NOT_WILLING_TO_WAIVE = 'Not willing to waive',
  NOT_RESPONDED = 'Not responded',
}

export type FundIndexEligibilityDetails = {
  fund_id?: string
  index_family_id?: number
  performance_added_to_family_as_of?: string | null
  removed_from_family_after?: string | null
  family_pending_removal?: boolean | null
  //
  accepting_new_investments?: boolean | null
  accepting_new_investments_index_eligibility?: INDEX_ELIGIBILITY_ENUM | null
  accepting_new_investments_last_confirmed_date?: string | null
  accepting_new_investments_confirmed_by?: string | null
  //
  subscriptions_monthly?: 'Daily' | 'Weekly' | 'Semi-Monthly' | 'Monthly' | null
  subscriptions_monthly_index_eligibility?: INDEX_ELIGIBILITY_ENUM | null
  subscriptions_monthly_last_confirmed_date?: string | null
  subscriptions_monthly_confirmed_by?: string | null
  //
  subscriptions_advanced?: number | null
  subscriptions_advanced_index_eligibility?: INDEX_ELIGIBILITY_ENUM | null
  subscriptions_advanced_last_confirmed_date?: string | null
  subscriptions_advanced_confirmed_by?: string | null
  //
  redemptions_quarterly?: 'Daily' | 'Weekly' | 'Semi-Monthly' | 'Monthly' | 'Bi-Monthly' | 'Quarterly' | null
  redemptions_quarterly_index_eligibility?: INDEX_ELIGIBILITY_ENUM | null
  redemptions_quarterly_last_confirmed_date?: string | null
  redemptions_quarterly_confirmed_by?: string | null
  //
  redemptions_advanced?: number | null
  redemptions_advanced_index_eligibility?: INDEX_ELIGIBILITY_ENUM | null
  redemptions_advanced_last_confirmed_date?: string | null
  redemptions_advanced_confirmed_by?: string | null
  //
  lockup?: WAIVE_ENUM | null
  lockup_index_eligibility?: INDEX_ELIGIBILITY_ENUM | null
  lockup_last_confirmed_date?: string | null
  lockup_confirmed_by?: string | null
  //
  gate_investor?: WAIVE_ENUM | null
  gate_investor_index_eligibility?: INDEX_ELIGIBILITY_ENUM | null
  gate_investor_last_confirmed_date?: string | null
  gate_investor_confirmed_by?: string | null
  //
  gate_fund?: WAIVE_ENUM | null
  gate_fund_index_eligibility?: INDEX_ELIGIBILITY_ENUM | null
  gate_fund_last_confirmed_date?: string | null
  gate_fund_confirmed_by?: string | null
  //
  eligibility_status?: INDEX_ELIGIBILITY_ENUM | null
  eligibility_status_reviewed_date?: string | null
  eligibility_status_reviewed_by?: string | null
  //
  exclusion_reason?: 'Due Diligence' | 'Firm concentration limit' | 'Redundant fund' | 'Other' | null
  exclusion_reason_reviewed_date?: string | null
  exclusion_reason_reviewed_by?: string | null
}

export type FundIndexEligibilityDetailsResponse = FundIndexEligibilityDetails & {
  id: string
  created_date: string
  updated_date: string
}

export const FundIndexEligibilityDetailsKeys: (keyof FundIndexEligibilityDetails)[] = [
  'performance_added_to_family_as_of',
  'removed_from_family_after',
  'family_pending_removal',
  'accepting_new_investments',
  'accepting_new_investments_index_eligibility',
  'accepting_new_investments_last_confirmed_date',
  'accepting_new_investments_confirmed_by',
  'subscriptions_monthly',
  'subscriptions_monthly_index_eligibility',
  'subscriptions_monthly_last_confirmed_date',
  'subscriptions_monthly_confirmed_by',
  'subscriptions_advanced',
  'subscriptions_advanced_index_eligibility',
  'subscriptions_advanced_last_confirmed_date',
  'subscriptions_advanced_confirmed_by',
  'redemptions_quarterly',
  'redemptions_quarterly_index_eligibility',
  'redemptions_quarterly_last_confirmed_date',
  'redemptions_quarterly_confirmed_by',
  'redemptions_advanced',
  'redemptions_advanced_index_eligibility',
  'redemptions_advanced_last_confirmed_date',
  'redemptions_advanced_confirmed_by',
  'lockup',
  'lockup_index_eligibility',
  'lockup_last_confirmed_date',
  'lockup_confirmed_by',
  'gate_investor',
  'gate_investor_index_eligibility',
  'gate_investor_last_confirmed_date',
  'gate_investor_confirmed_by',
  'gate_fund',
  'gate_fund_index_eligibility',
  'gate_fund_last_confirmed_date',
  'gate_fund_confirmed_by',
  'eligibility_status',
  'eligibility_status_reviewed_date',
  'eligibility_status_reviewed_by',
  'exclusion_reason',
  'exclusion_reason_reviewed_date',
  'exclusion_reason_reviewed_by',
]
