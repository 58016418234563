import { Col, Form, List, Radio, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FormItemRadio, FormItemSelect, FormItemTextArea } from '../../../shared/components/FormItemComponents'
import { RenderLabelExFund } from '../../../shared/components/RenderLabelExFund'
import { RenderLabelExNewFund } from '../../../shared/components/RenderLabelExNewFund'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { NextBackNewFundTab } from './NextBackExNewFundTab'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  flex-direction: column;
  padding: 0 2rem;
`
type Props = {
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  stepTab: LeftNavExNewFundEnum
  form: FormInstance
}
const ARRAY_FIELD_VALIDATE: string[] = []

const ListItem = styled(List.Item)`
  padding: 0 24px;
`

export const ExNewFundESGFactor: React.FC<Props> = ({ setStepTab, setActiveTab, stepTab, activeTab, form }) => {
  const [isConsiderESG, setIsConsiderESG] = React.useState(form.getFieldValue('is_fund_esg') === '1')
  const selectedSubStrategyCodeNew = form.getFieldValue('sub_strategy_code_new')

  const esgStrategyCode = form.getFieldValue('esg_strategy')
  const esgSubStrategyCode = form.getFieldValue('esg_sub_strategy')

  const { esgStrategies, esgSubStrategies, esgSubStrategyDropdown } = useSelector((state: RootState) => state.appData)

  React.useEffect(() => {
    if (!isConsiderESG) {
      form.setFieldsValue({ esg_strategy: undefined })
    }
  }, [form, isConsiderESG])

  // Reset esg sub-strategy whenever esg changed
  React.useEffect(() => {
    const currentESGSubStrategy = esgSubStrategies?.find((item) => item.code === esgSubStrategyCode)
    if (esgStrategyCode !== currentESGSubStrategy?.esg_strategy) {
      form.setFieldsValue({ esg_sub_strategy: undefined })
    }
  }, [form, esgStrategyCode, esgSubStrategyCode, esgSubStrategies])

  const { selectableESGStrategies, selectableESGSubStrategies } = React.useMemo(() => {
    if (!selectedSubStrategyCodeNew || !esgSubStrategyDropdown) {
      return { selectableESGStrategies: undefined, selectableESGSubStrategies: undefined }
    }
    const availableESGSubStrategyCodes = esgSubStrategyDropdown[selectedSubStrategyCodeNew]
    // set selectable options to undefined if sub_strategy_code_new is not match any Sub Strategy Dropdown
    if (!availableESGSubStrategyCodes) {
      return { selectableESGStrategies: undefined, selectableESGSubStrategies: undefined }
    }
    //
    const availableESGSubStrategies = esgSubStrategies
      ? esgSubStrategies.filter((item) => availableESGSubStrategyCodes.includes(item.code))
      : undefined

    return {
      selectableESGStrategies: esgStrategies
        ? esgStrategies.filter((item) => availableESGSubStrategies?.some((i) => i.esg_strategy === item.code))
        : undefined,
      selectableESGSubStrategies: availableESGSubStrategies,
    }
  }, [selectedSubStrategyCodeNew, esgStrategies, esgSubStrategies, esgSubStrategyDropdown])

  // Reset esg strategy whenever sub_strategy_code_new changed
  React.useEffect(() => {
    if (!selectableESGStrategies?.some((item) => item.code === esgStrategyCode)) {
      form.setFieldsValue({ esg_strategy: undefined })
    }
    if (!selectableESGSubStrategies?.some((item) => item.code === esgSubStrategyCode)) {
      form.setFieldsValue({ esg_sub_strategy: undefined })
    }
    form.setFieldsValue({ esg_stategy: undefined })
  }, [
    esgStrategyCode,
    esgSubStrategyCode,
    form,
    selectableESGStrategies,
    selectableESGSubStrategies,
    selectedSubStrategyCodeNew,
  ])

  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const DESCRIBE_BEST_DESCRIBE = [
    {
      name: 'app_positivescreen',
      label: 'Positive/best-in-class Screening',
    },
    {
      name: 'app_negativescreen',
      label: 'Negative/Exclusionary Screening',
    },
    {
      name: 'app_esgintegration',
      label: 'ESG Integration',
    },
    {
      name: 'app_impactinvest',
      label: 'Impact Investing',
    },
    {
      name: 'app_sustaininvest',
      label: 'Sustainability-themed Investing',
    },
  ]
  const DESCRIBE_ESG_CRITERIA = [
    {
      name: 'env_greenbuilding',
      label: 'Green Building/ Smart Growth',
    },
    {
      name: 'env_climatechange',
      label: 'Climate Change/ Carbon',
    },
    {
      name: 'env_cleantech',
      label: 'Clean Technology',
    },
    {
      name: 'env_pollutiontaxes',
      label: 'Pollution / Toxics',
    },
    {
      name: 'env_sustainable',
      label: 'Sustainable Natural Resources / Agriculture',
    },
    {
      name: 'env_waterconservation',
      label: 'Water Use & Conservation',
    },
    {
      name: 'env_description',
      label: 'Additional Description Of How ENVIRONMENTAL Is Considered When Investing',
      isInput: true,
    },
  ]
  const DESCRIBE_ESG_SOCIAL = [
    {
      name: 'soc_workplacesafety',
      label: 'Workplace Safety',
    },
    {
      name: 'soc_laborrelations',
      label: 'Labor Relations',
    },
    {
      name: 'soc_workplacebenefits',
      label: 'Workplace Benefits',
    },
    {
      name: 'soc_diversityantibias',
      label: 'Diversity & Anti-Bias Issues',
    },
    {
      name: 'soc_communitydev',
      label: 'Community Development',
    },
    {
      name: 'soc_avoidtobacco',
      label: 'Avoidance Of Tobacco Or Other Harmful Products',
    },
    {
      name: 'soc_humanrights',
      label: 'Human Rights',
    },
    {
      name: 'soc_description',
      label: 'Additional Description Of How SOCIAL Is Considered When Investing',
      isInput: true,
    },
  ]
  const DESCRIBE_GOVERNANCE = [
    {
      name: 'gov_corppolcontrib',
      label: 'Corporate Political Contributions',
    },
    {
      name: 'gov_executivecomp',
      label: 'Executive Compensation',
    },
    {
      name: 'gov_boarddiversity',
      label: 'Board Diversity',
    },
    {
      name: 'gov_anticorruption',
      label: 'Anti-Corruption Pilicies',
    },
    {
      name: 'gov_boardindependence',
      label: 'Board Independence',
    },
    {
      name: 'gov_description',
      label: 'Additional Description Of How GOVERNANCE Is Considered When Investing',
      isInput: true,
    },
  ]
  const ESG_DATA_SOURCES = [
    {
      name: 'data_msci',
      label: 'MSCI',
    },
    {
      name: 'data_sustainalytics',
      label: 'Sustainalytics',
    },
    {
      name: 'data_bloomberg',
      label: 'Bloomberg',
    },
    {
      name: 'data_factset',
      label: 'FactSet',
    },
    {
      name: 'data_internal',
      label: 'Proprietary Or Internally Developed Metrics',
    },
    {
      name: 'data_other',
      label: 'Other',
      isInput: true,
    },
  ]
  const LabelComponent = () => (
    <span>
      <i style={{ fontSize: '12px' }}>
        &nbsp; Note: ESG Strategy/Sub-Strategy options dependent on Main Strategy / Sub-strategy classifications in HFR
        Database (‘Investment Strategy’ module)
      </i>
    </span>
  )

  const optionEsg =
    fundDataDropdown?.is_fund_esg && fundDataDropdown.is_fund_esg.length > 0
      ? fundDataDropdown.is_fund_esg
          .map((item) => ({
            value: item.code_code,
            text: item.code_description,
          }))
          .filter((item) => item.value !== '0')
      : []

  return (
    <Wrapper>
      <Row>
        <Col span={24}>
          <FormItemRadio
            name="is_fund_esg"
            label={<RenderLabelExNewFund value="Does Fund Consider ESG Factors?" />}
            options={optionEsg.map((item) => ({ value: item.value, title: item.text }))}
            radioProps={{
              onChange: (e) => {
                setIsConsiderESG(e.target.value === '1')
              },
            }}
          />
        </Col>
      </Row>
      <Row style={{ flexDirection: 'column' }}>
        <div style={{ fontStyle: 'italic' }}>If Yes, please answer questions:</div>
        <Row>
          <Col span={24} xl={24} md={16} xs={24} sm={24} style={{ position: 'relative' }}>
            <FormItemSelect
              name={'esg_strategy'}
              label={<RenderLabelExFund value={'ESG Strategy'} subtitle={LabelComponent()} />}
              wrapperCol={10}
              labelCol={24}
              disabled={!isConsiderESG}
              allowClear
              options={
                selectableESGStrategies && selectableESGStrategies.length > 0
                  ? selectableESGStrategies.map((item) => ({ value: item.code, text: item.name }))
                  : []
              }
            />
            <Col style={{ position: 'absolute', top: '50%', left: '45%' }}>
              ESG Strategy Definitions available here: &nbsp;
              <Link to="/ESG_Strategy_Definitions.pdf" target="_blank">
                HFR ESG Definitions PDF
              </Link>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemSelect
              name={'esg_sub_strategy'}
              label={<RenderLabelExFund value={'ESG Sub-Strategy'} />}
              wrapperCol={16}
              labelCol={5}
              allowClear
              disabled={!isConsiderESG || !form.getFieldValue('esg_strategy')}
              options={
                selectableESGSubStrategies && selectableESGSubStrategies.length > 0
                  ? selectableESGSubStrategies
                      .filter((item) => item.esg_strategy === form.getFieldValue('esg_strategy'))
                      .map((item) => ({ value: item.code, text: item.name }))
                  : []
              }
            />
          </Col>
        </Row>
        <div style={{ fontWeight: 'bold', marginTop: '1rem' }}>
          DESCRIBE WHICH BEST DESCRIBES THE FUND&quot;S APPLICATION OF ESG
        </div>
        <div style={{ fontStyle: 'italic' }}>Select &quot;Yes&quot; or &quot;No&quot; foreach:</div>
        <List
          bordered
          dataSource={DESCRIBE_BEST_DESCRIBE}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <ListItem>
              <div style={{ width: '100%' }}>
                <Form.Item
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  // rules={[{ required: true, message: 'This Field is required!' }]}
                  {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                  style={{ flexDirection: 'row', alignItems: 'baseline', marginBottom: '0' }}
                >
                  <Radio.Group disabled={!isConsiderESG} style={{ minHeight: '1px' }}>
                    <Radio style={{ minHeight: '2px' }} value="1">
                      Yes
                    </Radio>
                    <Radio value="2">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </ListItem>
          )}
        />
      </Row>
      <Row style={{ flexDirection: 'column', marginTop: '1rem' }}>
        <div style={{ fontStyle: 'italic' }}>Select &quot;Yes&quot; or &quot;No&quot; foreach:</div>
        <List
          header={<h4 style={{ margin: 0 }}>ENVIRONMENTAL</h4>}
          bordered
          dataSource={DESCRIBE_ESG_CRITERIA}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <ListItem>
              <div style={{ width: '100%' }}>
                {item.isInput ? (
                  <FormItemTextArea
                    name={item.name}
                    label={<RenderLabelExNewFund value={item.label} />}
                    row={3}
                    disable={!isConsiderESG}
                  />
                ) : (
                  <Form.Item
                    name={item.name}
                    label={<RenderLabelExNewFund value={item.label} />}
                    // rules={[{ required: true, message: 'This Field is required!' }]}
                    {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                    style={{ flexDirection: 'row', alignItems: 'baseline', marginBottom: '0' }}
                  >
                    <Radio.Group disabled={!isConsiderESG}>
                      <Radio value="1">Yes</Radio>
                      <Radio value="2">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              </div>
            </ListItem>
          )}
        />
      </Row>
      <Row style={{ flexDirection: 'column', marginTop: '1rem' }}>
        <div style={{ fontWeight: 'bold' }}>DESCRIBE ESG CRITERIA</div>
        <div style={{ fontStyle: 'italic' }}>Select &quot;Yes&quot; or &quot;No&quot; foreach:</div>
        <List
          header={<h4 style={{ margin: 0 }}>SOCIAL</h4>}
          bordered
          dataSource={DESCRIBE_ESG_SOCIAL}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <ListItem>
              <div style={{ width: '100%' }}>
                {item.isInput ? (
                  <FormItemTextArea
                    name={item.name}
                    label={<RenderLabelExNewFund value={item.label} />}
                    row={3}
                    disable={!isConsiderESG}
                  />
                ) : (
                  <Form.Item
                    name={item.name}
                    label={<RenderLabelExNewFund value={item.label} />}
                    // rules={[{ required: true, message: 'This Field is required!' }]}
                    {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                    style={{ flexDirection: 'row', alignItems: 'baseline', marginBottom: '0' }}
                  >
                    <Radio.Group disabled={!isConsiderESG}>
                      <Radio value="1">Yes</Radio>
                      <Radio value="2">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              </div>
            </ListItem>
          )}
        />
      </Row>

      <Row style={{ flexDirection: 'column', marginTop: '1rem' }}>
        <div style={{ fontStyle: 'italic' }}>Select &quot;Yes&quot; or &quot;No&quot; foreach:</div>
        <List
          header={<h4 style={{ margin: 0 }}>GOVERNANCE</h4>}
          bordered
          dataSource={DESCRIBE_GOVERNANCE}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <ListItem>
              <div style={{ width: '100%' }}>
                {item.isInput ? (
                  <FormItemTextArea
                    name={item.name}
                    label={<RenderLabelExNewFund value={item.label} />}
                    row={3}
                    disable={!isConsiderESG}
                  />
                ) : (
                  <Form.Item
                    name={item.name}
                    label={<RenderLabelExNewFund value={item.label} />}
                    // rules={[{ required: true, message: 'This Field is required!' }]}
                    {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                    style={{ flexDirection: 'row', alignItems: 'baseline', marginBottom: '0' }}
                  >
                    <Radio.Group disabled={!isConsiderESG}>
                      <Radio value="1">Yes</Radio>
                      <Radio value="2">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              </div>
            </ListItem>
          )}
        />
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col span={24}>
          <Form.Item
            name="team_engagepcm"
            label={
              <RenderLabelExNewFund value="Does This Fund's Investment Team Engage With The Management Teams Of Companies In This Fund's Underlying Poritfolio, Re:ESG Or Diversity Issues?" />
            }
            // rules={[{ required: true, message: 'This Field is required!' }]}
          >
            <Radio.Group disabled={!isConsiderESG}>
              <Radio value="1">Yes</Radio>
              <Radio value="2">No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ flexDirection: 'column', marginTop: '1rem' }}>
        <div style={{ fontWeight: 'bold' }}>WHAT ESG DATA SOURCES ARE USED IN YOUR PROCESS</div>
        <div style={{ fontStyle: 'italic' }}>Select &quot;Yes&quot; or &quot;No&quot; foreach:</div>
        <List
          bordered
          dataSource={ESG_DATA_SOURCES}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <ListItem>
              <div style={{ width: '100%' }}>
                {item.isInput ? (
                  <FormItemTextArea
                    name={item.name}
                    label={<RenderLabelExNewFund value={item.label} />}
                    row={3}
                    disable={!isConsiderESG}
                  />
                ) : (
                  <Form.Item
                    name={item.name}
                    label={<RenderLabelExNewFund value={item.label} />}
                    // rules={[{ required: true, message: 'This Field is required!' }]}
                    {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                    style={{ flexDirection: 'row', alignItems: 'baseline', marginBottom: '0' }}
                  >
                    <Radio.Group disabled={!isConsiderESG}>
                      <Radio value="1">Yes</Radio>
                      <Radio value="2">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              </div>
            </ListItem>
          )}
        />
      </Row>
      <NextBackNewFundTab
        setStepTab={setStepTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        stepTab={stepTab}
        ArrayValidateField={ARRAY_FIELD_VALIDATE}
        form={form}
      />
    </Wrapper>
  )
}
