import { Card, Col, Empty, Form, notification, Row } from 'antd'
import { useForm } from 'antd/lib/form/util'
import moment from 'moment'
import React, { SetStateAction, useCallback, useRef } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { isNull } from 'util'
import APIService from '../../../shared/api'
import { ExNewFundContext } from '../../../shared/api/context/ExNewFundContext'
import { ExNewFundLabelSearchProvider } from '../../../shared/api/context/ExNewFundLabelSearchContext'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { ExFundFieldChange, FundDetails } from '../../../shared/api/models/FundDetails'
import { MinorityType } from '../../../shared/api/services/minority.service'
import { Colors } from '../../../shared/colors'
import { TabContentWrapper } from '../../../shared/components/TabContentWrapper'
import { useDraft } from '../../../shared/DraftManager'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import { LeftNavExNewFundEnum, renderSearchListExNewFund } from '../../../shared/SearchExNewFundLabel'
import { addTabs, FundFirmTypeEnum, PREFIX_EXTERNAL, removeTabs } from '../../../slice/appSettingsSlice'
import CMIC from '../../Dashboard/FundDetail/CMIC/CMIC'
import { ExNewFundContact } from './ExNewFundContact'
import { ExNewFundCreateFundProfile } from './ExNewFundCreateFundProfile'
import ExNewFundDI from './ExNewFundDI'
import { ExNewFundESGFactor } from './ExNewFundESGFactor'
import { ExNewFundFeeSchedule } from './ExNewFundFeeSchedule'
import { ExNewFundFinalStep } from './ExNewFundFinalStep'
import { ExNewFundInstruction } from './ExNewFundInstruction'
import { ExNewFundInvestStrategy } from './ExNewFundInvestStrategy'
import { ExNewFundPerformance } from './ExNewFundPerformance'
import { ExNewFundRequirement } from './ExNewFundRequirement'
import { ExNewFundServiceProviders } from './ExNewFundServiceProvider'
import { ExNewFundStructure } from './ExNewFundStructure'
import { ExNewFundTitle } from './ExNewFundTitle'
import { NextBackNewFundTab } from './NextBackExNewFundTab'

const Flex = styled.div`
  display: flex;
  flex: 1;
`
const StyledCard = styled(Card)`
  overflow: hidden;
  .ant-card-head {
    padding: 0 1rem;
  }
  .ant-card-head-title {
    padding: 0;
  }
  .ant-card-body {
    padding: 0;
    overflow: hidden;
  }
  .ant-card-head {
    border-bottom: 2px solid #f0f0f0 !important;
  }
`
const StyledForm = styled(Form)`
  flex: 1;
  overflow: hidden;
  height: 100%;
  .ant-form-item {
    margin-bottom: 8px;
  }
`

const StyledTabList = styled.div<{ active?: boolean; isCanSelect?: boolean }>`
  background: ${(props) => (props.active ? '#EDF6FF' : '#fff')};
  font-weight: ${(props) => (props.active ? 'bold' : 'none')};
  color: ${(props) => (props.isCanSelect ? Colors.black : Colors.gray)};
  display: flex;
  flex: 1;
  align-items: center;
  border-left: solid 1px ${Colors.border};
  border-right: solid 1px ${Colors.border};
  border-bottom: solid 1px ${Colors.border};
  border-top: none;
  padding: 1rem 0 1rem 3rem;
  :hover {
    cursor: pointer;
  }
`

export const TAB_LIST_CREATE_NEW_FUND = [
  {
    id: LeftNavExNewFundEnum.INSTRUCTION,
  },
  {
    id: LeftNavExNewFundEnum.CREATE_FUND_PROFILE,
  },
  {
    id: LeftNavExNewFundEnum.INVESTMENT_STRATEGY,
  },
  {
    id: LeftNavExNewFundEnum.INVESTMENT_REQUIREMENTS,
  },
  {
    id: LeftNavExNewFundEnum.CONTACT_INFORMATION,
  },
  {
    id: LeftNavExNewFundEnum.STRUCTURE,
  },
  {
    id: LeftNavExNewFundEnum.FEE_SCHEDULE,
  },
  {
    id: LeftNavExNewFundEnum.SERVICE_PROVIDERS,
  },
  {
    id: LeftNavExNewFundEnum.FUND_ESG_FACTORS,
  },
  {
    id: LeftNavExNewFundEnum.FUND_DIVERSITY_INCLUSION,
  },
  {
    id: LeftNavExNewFundEnum.FUND_PERFORMANCE,
  },
  {
    id: LeftNavExNewFundEnum.FUND_CMIC,
  },
  {
    id: LeftNavExNewFundEnum.COMPLETE,
  },
]
const dateFormattedFields = [
  'firm_assets_date',
  'fund_assets_date',
  'inception_date',
  'om_date',
  'liquidated_at',
  'last_audit_date',
  'most_recent_review',
  'hfri500_reviewed',
]

const fieldFormatNumber = ['min_invest', 'add_invest']
const fieldFormatInput = ['auditor_name', 'legal_adviser', 'prime_broker', 'administrator', 'domicile']
const fieldInputNotAdd = [
  'auditor_name_input',
  'legal_adviser_input',
  'prime_broker_input',
  'administrator_input',
  'domicile_input',
  '_rorDataChange',
  '_assetDataChange',
  '_navDataChange',
]

type Props = { setShouldUpdate: (value: boolean) => void }

export const ExNewFundMain: React.FC<Props> = () => {
  const {
    rorDataChange,
    assetDataChange,
    navDataChange,
    setRORDataChange,
    setAssetDataChange,
    setNAVDataChange,
    fundDataDropdown,
  } = React.useContext(ExNewFundContext)
  const [activeTab, setActiveTab] = React.useState(LeftNavExNewFundEnum.INSTRUCTION)
  const [optionFilter, setOptionFilter] = React.useState(renderSearchListExNewFund)
  const [textFilterSearch, settextFilterSearch] = React.useState('')
  const [loadingSubmitForm, setLoadingSubmitForm] = React.useState(false)
  // const [tabList, setTabList] = React.useState(TAB_LIST_CREATE_NEW_FUND)
  const [stepTab, setStepTab] = React.useState(LeftNavExNewFundEnum.INSTRUCTION)
  const [isUpdated, setIsUpdated] = React.useState(false)
  const { dataFirms, firmIdChoose } = React.useContext(FundDetailDropdownContext)
  const { clearSnapshot, loadSnapshot, saveSnapshot } = useDraft()
  const dataFundDetailChangeRef = useRef<Partial<FundDetails & ExFundFieldChange>>({})
  // const [dataFundDetailChange, setDataFundDetailChange] = React.useState<Partial<FundDetails & ExFundFieldChange>>({})

  const [form] = useForm()
  const dispatch = useDispatch()

  const { invoke: getFirmDetail, data: dataFirmDetail, loading } = useServiceState(
    APIService.firmDetailService.fetchFirmInfo,
  )

  React.useEffect(() => {
    if (firmIdChoose) {
      getFirmDetail(firmIdChoose)
    }
  }, [firmIdChoose, getFirmDetail])

  const { invoke: getAllMinorityTypes, data: minorityTypes } = useServiceState(
    APIService.minorityService.fetchMinorityTypes,
  )

  React.useEffect(() => {
    const countryCode = dataFirmDetail?.country
    if (!countryCode) {
      return
    }
    getAllMinorityTypes(countryCode)
  }, [dataFirmDetail?.country, getAllMinorityTypes])

  const firmCountry = fundDataDropdown?.firm_contact_country.find((item) => item.code_code === dataFirmDetail?.country)

  React.useEffect(() => {
    const draft = loadSnapshot('exFundDraft', 'new-fund')
    if (draft) {
      dataFundDetailChangeRef.current = draft
      setIsUpdated(true)
      setRORDataChange(draft._rorDataChange || { values: undefined })
      setAssetDataChange(draft._assetDataChange || { values: undefined })
      setNAVDataChange(draft._navDataChange || { values: undefined })
      form.setFieldsValue(dataFundDetailChangeRef.current)

      // @ts-ignore
      form.draft = draft
    }
  }, [form, loadSnapshot, setAssetDataChange, setNAVDataChange, setRORDataChange])

  const debouceRef = useRef<any>(null)
  const setDataFundDetailChange = useCallback(
    (action: SetStateAction<Partial<FundDetails & ExFundFieldChange>>) => {
      setIsUpdated(true)
      dataFundDetailChangeRef.current = typeof action === 'object' ? action : action(dataFundDetailChangeRef.current)
      if (debouceRef.current) {
        clearTimeout(debouceRef.current)
      }

      debouceRef.current = setTimeout(() => {
        saveSnapshot('exFundDraft', {
          data: Object.keys(dataFundDetailChangeRef.current).length > 0 ? dataFundDetailChangeRef.current : undefined,
          id: 'new-fund',
          name: 'New Fund',
        })
      }, 300)
    },
    [saveSnapshot],
  )

  React.useEffect(() => {
    setDataFundDetailChange((prevState) => ({
      ...prevState,
      _rorDataChange: rorDataChange,
      _assetDataChange: assetDataChange,
      _navDataChange: navDataChange,
    }))
  }, [rorDataChange, assetDataChange, navDataChange, setDataFundDetailChange])

  const handleSubmit = async () => {
    const fieldChange: Partial<FundDetails & ExFundFieldChange> = Object.keys(dataFundDetailChangeRef.current).reduce(
      (accum, key) => {
        if (fieldFormatInput.includes(key)) {
          if (dataFundDetailChangeRef.current[key as keyof FundDetails] === 'Other') {
            switch (key) {
              case 'auditor_name':
                return { ...accum, auditor_name: dataFundDetailChangeRef.current.auditor_name_input }
              case 'legal_adviser':
                return { ...accum, legal_adviser: dataFundDetailChangeRef.current.legal_adviser_input }
              case 'prime_broker':
                return { ...accum, prime_broker: dataFundDetailChangeRef.current.prime_broker_input }
              case 'administrator':
                return { ...accum, administrator: dataFundDetailChangeRef.current.administrator_input }
              case 'domicile':
                return { ...accum, domicile: dataFundDetailChangeRef.current.domicile_input }
              default:
                break
            }
          }
        }
        if (fieldInputNotAdd.includes(key)) {
          return accum
        }

        if (key === 'min_invest_indv') {
          return dataFundDetailChangeRef.current['accepts_mgd_accts'] === '2'
            ? accum
            : {
                ...accum,
                [key]: dataFundDetailChangeRef.current['min_invest_indv']
                  ? parseInt(dataFundDetailChangeRef.current['min_invest_indv'].replace(/,0/g, '0'))
                  : 0,
              }
        }

        if (key === 'run_sep_mgd_accts' && dataFundDetailChangeRef.current['accepts_mgd_accts'] === '2') {
          return accum
        }

        if (fieldFormatNumber.includes(key)) {
          if (dataFundDetailChangeRef.current[key as keyof FundDetails] === 'N/A') {
            return { ...accum, [key]: null }
          } else {
            const value = dataFundDetailChangeRef.current[key as keyof FundDetails]
              ? (dataFundDetailChangeRef.current[key as keyof FundDetails] as string).replace(/,0/g, '0')
              : null

            return {
              ...accum,
              [key]: value ? parseInt(value) : 0,
            }
          }
        }

        if (dateFormattedFields.includes(key)) {
          const value = dataFundDetailChangeRef.current[key as keyof FundDetails]
          return { ...accum, [key]: moment(value as string).format('YYYY-MM-DD') }
        }

        return {
          ...accum,
          [key]: dataFundDetailChangeRef.current[key as keyof FundDetails],
        }
      },
      {},
    )
    setLoadingSubmitForm(true)

    // Filter MinoryType
    const dataMinority = Object.entries(fieldChange).filter((item) => item[0].includes('minority_type_'))
    const minorityChange = {
      updates: dataMinority.map((item) => {
        return {
          minority_type: +item[0].replace('minority_type_', ''),
          status: item[1] as boolean,
        }
      }),
    }

    try {
      const res = await APIService.fundDetailService.createFundBasicInfo(fieldChange)
      // Update Minority Api

      notification.success({ message: 'Fund Created!' })
      if (res.data.fund_id) {
        await Promise.all([
          handleSubmitMinorityType(res.data.fund_id, minorityChange),
          handleSubmitROR(res.data.fund_id),
          handleSubmitAsset(res.data.fund_id),
          handleSubmitNav(res.data.fund_id),
        ])

        dispatch(removeTabs(String(`${PREFIX_EXTERNAL}-new-fund`)))
        setDataFundDetailChange({})
        clearSnapshot('exFundDraft', `new-fund`)
        dispatch(addTabs({ id: `${PREFIX_EXTERNAL}-${res.data.fund_id}`, type: FundFirmTypeEnum.FUNDS }))
      }
    } catch (err) {
      console.error({ err })
      notification.error({ message: 'Create Failed!' })
    } finally {
      setLoadingSubmitForm(false)
    }
  }

  const handleSubmitMinorityType = async (fundId: string, minorityChange: any) => {
    if (minorityChange && minorityChange.updates.length > 0 && dataFirmDetail) {
      APIService.minorityService.updateFundMinorityStatuses(fundId, minorityChange)
    }
  }

  const handleSubmitROR = async (fundId: string) => {
    if (rorDataChange.values && rorDataChange.values.length > 0) {
      try {
        setLoadingSubmitForm(true)
        rorDataChange &&
          (await APIService.fundDetailService.updateFundROR(fundId, JSON.parse(JSON.stringify(rorDataChange))))
        // refetch data
        notification.success({ message: 'ROR Created' })

        setRORDataChange({ values: undefined })
      } catch (error) {
        console.log(error.response)
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
      }
    }
  }
  const handleSubmitAsset = async (fundId: string) => {
    if (assetDataChange.values && assetDataChange.values.length > 0) {
      try {
        setLoadingSubmitForm(true)
        assetDataChange &&
          (await APIService.fundDetailService.updateAssetROR(fundId, JSON.parse(JSON.stringify(assetDataChange))))
        // refetch data
        notification.success({ message: 'Assets Created' })
        setAssetDataChange({ values: undefined })
      } catch (error) {
        console.log(error.response)
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
      }
    }
  }
  const handleSubmitNav = async (fundId: string) => {
    if (navDataChange.values && navDataChange.values.length > 0) {
      try {
        setLoadingSubmitForm(true)
        navDataChange &&
          (await APIService.fundDetailService.updateNAVROR(fundId, JSON.parse(JSON.stringify(navDataChange))))
        // refetch data
        notification.success({ message: 'NAV Created' })
        setNAVDataChange({ values: undefined })
      } catch (error) {
        console.log(error.response)
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
      }
    }
  }

  const renderTabContent = (activeTabId: LeftNavExNewFundEnum) => {
    const activeTab = TAB_LIST_CREATE_NEW_FUND.find((item) => item.id === activeTabId)
    if (!activeTab) {
      return
    }
    switch (activeTabId) {
      case LeftNavExNewFundEnum.INSTRUCTION:
        return <ExNewFundInstruction setStepTab={setStepTab} setActiveTab={setActiveTab} />
      case LeftNavExNewFundEnum.CREATE_FUND_PROFILE:
        return (
          <ExNewFundCreateFundProfile
            form={form}
            setStepTab={setStepTab}
            setActiveTab={setActiveTab}
            activeTab={activeTabId}
            stepTab={stepTab}
            setDataFundDetailChange={setDataFundDetailChange}
          />
        )
      case LeftNavExNewFundEnum.INVESTMENT_STRATEGY:
        return (
          <ExNewFundInvestStrategy
            form={form}
            setStepTab={setStepTab}
            setActiveTab={setActiveTab}
            activeTab={activeTabId}
            stepTab={stepTab}
            setDataFundDetailChange={setDataFundDetailChange}
            dataFundDetailChangeRef={dataFundDetailChangeRef}
          />
        )
      case LeftNavExNewFundEnum.INVESTMENT_REQUIREMENTS:
        return (
          <ExNewFundRequirement
            form={form}
            setStepTab={setStepTab}
            setActiveTab={setActiveTab}
            activeTab={activeTabId}
            stepTab={stepTab}
          />
        )
      case LeftNavExNewFundEnum.CONTACT_INFORMATION:
        return (
          <ExNewFundContact
            form={form}
            setStepTab={setStepTab}
            setActiveTab={setActiveTab}
            activeTab={activeTabId}
            stepTab={stepTab}
            setDataFundDetailChange={setDataFundDetailChange}
          />
        )
      case LeftNavExNewFundEnum.STRUCTURE:
        return (
          <ExNewFundStructure
            form={form}
            setStepTab={setStepTab}
            setActiveTab={setActiveTab}
            activeTab={activeTabId}
            stepTab={stepTab}
          />
        )
      case LeftNavExNewFundEnum.FEE_SCHEDULE:
        return (
          <ExNewFundFeeSchedule
            form={form}
            setStepTab={setStepTab}
            setActiveTab={setActiveTab}
            activeTab={activeTabId}
            stepTab={stepTab}
          />
        )
      case LeftNavExNewFundEnum.SERVICE_PROVIDERS:
        return (
          <ExNewFundServiceProviders
            form={form}
            setStepTab={setStepTab}
            setActiveTab={setActiveTab}
            activeTab={activeTabId}
            stepTab={stepTab}
          />
        )
      case LeftNavExNewFundEnum.FUND_ESG_FACTORS:
        return (
          <ExNewFundESGFactor
            form={form}
            setStepTab={setStepTab}
            setActiveTab={setActiveTab}
            activeTab={activeTabId}
            stepTab={stepTab}
          />
        )
      case LeftNavExNewFundEnum.FUND_DIVERSITY_INCLUSION:
        return (
          <ExNewFundDI
            form={form}
            setActiveTab={setActiveTab}
            setStepTab={setStepTab}
            activeTab={activeTabId}
            stepTab={stepTab}
            firmCountry={firmCountry}
            minorityTypes={minorityTypes}
            setDataFundDetailChange={setDataFundDetailChange}
          />
        )
      case LeftNavExNewFundEnum.FUND_PERFORMANCE:
        return (
          <ExNewFundPerformance
            form={form}
            setStepTab={setStepTab}
            setActiveTab={setActiveTab}
            activeTab={activeTabId}
            stepTab={stepTab}
          />
        )
      case LeftNavExNewFundEnum.FUND_CMIC:
        return (
          <Row>
            <Col span={24}>
              <CMIC form={form} />
            </Col>
            <Col span={24}>
              <NextBackNewFundTab
                setStepTab={setStepTab}
                setActiveTab={setActiveTab}
                activeTab={LeftNavExNewFundEnum.FUND_CMIC}
                stepTab={stepTab}
                ArrayValidateField={[]}
                form={form}
              />
            </Col>
          </Row>
        )
      case LeftNavExNewFundEnum.COMPLETE:
        return <ExNewFundFinalStep loadingSubmit={loadingSubmitForm} setActiveTab={setActiveTab} />
      default:
        break
    }
  }

  React.useEffect(() => {
    if (dataFirms && dataFirms.length === 1) {
      form.setFieldsValue({ firm_id: dataFirms[0].firm_id })
      setDataFundDetailChange((prev) => ({ ...prev, firm_id: dataFirms[0].firm_id }))
    }
  }, [dataFirms, form, setDataFundDetailChange])

  const handleCancel = () => {
    clearSnapshot('exFundDraft', `new-fund`)
    dispatch(removeTabs(`${PREFIX_EXTERNAL}-new-fund`))
  }
  return (
    <TabContentWrapper style={{ position: 'relative', background: 'white', height: '100%' }}>
      <StyledForm
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        onValuesChange={(changedValues) => {
          const convertObject = Object.keys(changedValues).reduce((acc, key) => {
            //format special field
            return { ...acc, [key]: changedValues[key] !== undefined ? changedValues[key] : null }
          }, {})

          setDataFundDetailChange({ ...dataFundDetailChangeRef.current, ...convertObject })
        }}
      >
        <StyledCard
          title={
            <ExNewFundTitle
              optionFillter={optionFilter}
              textFilterSearch={textFilterSearch}
              setOptionFilter={setOptionFilter}
              settextFilterSearch={settextFilterSearch}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              onCancel={handleCancel}
              isUpdateFund={isUpdated}
            />
          }
          size="default"
          style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <Row style={{ height: '100%' }}>
            <Col span={4} style={{ height: '100%' }}>
              <PerfectScrollbar>
                {TAB_LIST_CREATE_NEW_FUND.map((item) => {
                  const existTab = optionFilter.findIndex((i) => i.leftNav === item.id)
                  if (existTab === -1 && textFilterSearch !== '') {
                    return null
                  }
                  const indexTab = TAB_LIST_CREATE_NEW_FUND.findIndex((i) => i.id === item.id)
                  const indexStep = TAB_LIST_CREATE_NEW_FUND.findIndex((i) => i.id === stepTab)
                  return (
                    <StyledTabList
                      key={item.id}
                      onClick={() => {
                        if (indexTab > indexStep) {
                          return
                        }
                        form.validateFields().then(() => {
                          setActiveTab(item.id)
                        })
                      }}
                      active={activeTab === item.id}
                      isCanSelect={indexStep - indexTab >= 0}
                    >
                      <div style={{ position: 'absolute', left: '1.5rem' }}>
                        {/* {item.status === TAB_STATUS_ENUM.SUCCESS && <CheckCircleTwoTone twoToneColor="#52c41a" />}
                        {item.status === TAB_STATUS_ENUM.WARNING && <WarningTwoTone twoToneColor="#fa8c16" />} */}
                      </div>
                      {item.id}
                    </StyledTabList>
                  )
                })}
              </PerfectScrollbar>
            </Col>
            <Col span={20} style={{ height: '100%' }}>
              <PerfectScrollbar>
                <Flex style={{ borderRight: `solid 1px ${Colors.border}` }}>
                  <ExNewFundLabelSearchProvider text={textFilterSearch} filterArr={optionFilter}>
                    {optionFilter.length === 0 ? (
                      <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Empty />
                      </div>
                    ) : (
                      renderTabContent(activeTab)
                    )}
                  </ExNewFundLabelSearchProvider>
                </Flex>
              </PerfectScrollbar>
            </Col>
          </Row>
        </StyledCard>
      </StyledForm>
    </TabContentWrapper>
  )
}
