import React from 'react'
import styled from 'styled-components'
import { FundFamilyTable, FundFamilyType } from '../../../shared/components/FundFamilyTable'
import { FundDetailDataContext } from '../../../shared/api/context/FundDetailDataContext'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/rootReducer'

const Flex = styled.div`
  flex: 1;
  display: flex;
`

type Props = {}
export const ExFundFamily: React.FC<Props> = () => {
  const { dataFundDetail, fundDataDropdown, loading } = React.useContext(FundDetailDataContext)

  const { strategies: strategyOptions, subStrategies: subStrategyOptions } = useSelector(
    (state: RootState) => state.appData,
  )
  // map fund family data
  const fundFamilyData = React.useMemo<FundFamilyType[]>(() => {
    if (!dataFundDetail || !fundDataDropdown || !strategyOptions || !subStrategyOptions) {
      return []
    }
    //
    const stategy_op = dataFundDetail.strategy_code_new
      ? strategyOptions.find((s) => s.strategy_code === dataFundDetail.strategy_code_new)
      : null
    const stategy_code = stategy_op?.strategy_name
    //
    const sub_stategy_op = dataFundDetail.sub_strategy_code_new
      ? subStrategyOptions.find((s) => s.sub_strategy_code === dataFundDetail.sub_strategy_code_new)
      : null
    const sub_stategy_code = sub_stategy_op?.sub_strategy_name
    //
    const region_inv_focus_op = dataFundDetail.region_inv_focus
      ? fundDataDropdown.region_inv_focus.find((s) => s.code_code === dataFundDetail.region_inv_focus)
      : null
    const region_inv_focus = region_inv_focus_op?.code_description
    //
    const region_inv_focus_country_op = dataFundDetail.region_inv_focus_country
      ? fundDataDropdown.region_inv_focus_country.find((s) => s.code_code === dataFundDetail.region_inv_focus_country)
      : null
    const region_inv_focus_country = region_inv_focus_country_op?.code_description
    //
    return [
      {
        fund_id: dataFundDetail.fund_id,
        fund_name: dataFundDetail.fund_name,
        firm_id: dataFundDetail.firm_id,
        firm_name: dataFundDetail.firm_name,
        fund_family_id: dataFundDetail.fund_family_id,
        fund_family_code: dataFundDetail.fund_family_code,
        strategy_code: stategy_code,
        sub_strategy_code: sub_stategy_code,
        region_inv_focus,
        region_inv_focus_country,
      } as FundFamilyType,
    ]
  }, [dataFundDetail, strategyOptions, subStrategyOptions, fundDataDropdown])

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <FundFamilyTable version="fund" editable={false} isLoading={loading} fundFamilyData={fundFamilyData} />
    </Flex>
  )
}
