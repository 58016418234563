import { Form, Modal, notification, Spin } from 'antd'
import React from 'react'
import APIService from '../../shared/api'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { FirmMultiManagerPodPlatform } from '../Dashboard/FirmDetail/FirmMultiManagerPodPlatform/FirmMultiManagerPodPlatform'
import { MultiManagerPlatformType } from '../../shared/api/models/FirmDetails'
import { useForm } from 'antd/lib/form/util'
import { LoadingDetails } from '../../shared/components/LoadingDetails'
import axios from 'axios'
type Props = {
  showModal: boolean
  firmId: string
  firmName: string
  onCancel: () => void
}
export const ModalMultiManagerPlatform: React.FC<Props> = ({ showModal, firmId, firmName, onCancel }) => {
  const [form] = useForm()

  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [multiManagerPlatformChange, setMultiManagerPlatformChange] = React.useState<MultiManagerPlatformType[]>()

  const { invoke: getFirmCMIC, data: firmCMICData, loading: loadingCMICData } = useServiceState(
    APIService.firmDetailService.fetchFirmCMIC,
  )

  const {
    invoke: getMultiManagerPlatform,
    data: multiManagerPlatform,
    loading: loadingMultiManagerPlatform,
  } = useServiceState(APIService.firmDetailService.fetchMultiManagerPlatform)

  React.useEffect(() => {
    getFirmCMIC(firmId)
    getMultiManagerPlatform(firmId)
  }, [firmId])

  const handleSubmit = async () => {
    if (!multiManagerPlatform) {
      return
    }

    setLoadingSubmit(true)
    if (multiManagerPlatformChange && multiManagerPlatformChange.length > 0) {
      try {
        await APIService.firmDetailService.updateMultiManagerPlatform(firmId, {
          updates: multiManagerPlatformChange,
        })
        notification.success({ message: 'Multi Manager Platform Updated' })
        getMultiManagerPlatform(firmId)
        setMultiManagerPlatformChange(undefined)
        onCancel()
      } catch (err) {
        notification.error({
          message: `Update Failed! ${axios.isAxiosError(err) ? err.response && err.response.statusText : err}`,
        })
      } finally {
      }
    }
  }
  return (
    <Modal
      title={`Multi-Manager "Pod" Platform - ${firmName}`}
      visible={showModal}
      okText={loadingSubmit ? <Spin /> : 'Submit'}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: !multiManagerPlatformChange || multiManagerPlatformChange.length <= 0,
      }}
      onCancel={onCancel}
      width="80vw"
    >
      {firmCMICData && multiManagerPlatform && (
        <Form
          form={form}
          initialValues={{
            _multiManagerPlatformChange: multiManagerPlatform.funds.reduce((prev, cur) => {
              return {
                ...prev,
                [cur.fund_id]: cur,
              }
            }, {} as Record<string, MultiManagerPlatformType>),
          }}
          onValuesChange={(changedValues, allValues) => {
            // Multi Manager Platform
            const MMPChange = allValues._multiManagerPlatformChange
            if (changedValues._multiManagerPlatformChange) {
              const multiManagerPlatformChangeKeys = Object.keys(MMPChange)
              setMultiManagerPlatformChange(
                multiManagerPlatformChangeKeys.map((fundId) => {
                  return {
                    fund_id: fundId,
                    multi_manager_platform: MMPChange[fundId].multi_manager_platform,
                  } as MultiManagerPlatformType
                }),
              )
            }
          }}
        >
          <FirmMultiManagerPodPlatform
            form={form}
            onChangeAll={setMultiManagerPlatformChange}
            firmCMICData={firmCMICData}
            multiManagerPlatform={multiManagerPlatform}
            loadingCMICData={loadingCMICData}
            loadingMultiManagerPlatform={loadingMultiManagerPlatform}
          />
        </Form>
      )}
      {(loadingCMICData || loadingMultiManagerPlatform) && <LoadingDetails name="Loading Firm" />}
    </Modal>
  )
}
